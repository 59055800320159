@if (consignment) {
  @if ((isOwnPaperElasticConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS) || isOwnPersonalElasticConsignment(consignment) || isOwnMultiPaperElasticConsignment(consignment)){
    <icz-address-details
      [addressWithType]="addressToAddressWithType($any(consignment).consigneeAddress)"
    ></icz-address-details>
  }
  @if (isOwnDigitalElasticConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL) {
    <div>
      {{ consignment.consigneeEmail }}
    </div>
  }
  @if (isOwnDigitalElasticConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
    <div>
      <!-- todo(rb) implement invalidated/suspended databox warning after BE ready -->
      {{ consignment.consigneeDataBox }}
    </div>
  }
  @if (isOwnInternalElasticConsignment(consignment)) {
    <icz-address-details
      [addressWithType]="filingOfficeAddress(consignment.filingOfficeSheetNodeId!)"
    ></icz-address-details>
  }
  @if (isOwnFaxElasticConsignment(consignment)) {
    <div>
      {{ consignment.consigneePhoneNumber }}
    </div>
  }
  @if (isOwnPortalElasticConsignment(consignment)) {
    <div>
      {{ $any(consignment).url }}
    </div>
  }
}
