<mat-accordion class="grow">
  <mat-expansion-panel #expansionPanel
    (mouseenter)="mouseEnter()" (mouseleave)="mouseLeft()"
    [@.disabled]="animationDisable$ | async"
    [disabled]="!message.data!.body && !message.data!.buttons?.length"
    class="expansion-panel-white-arrow">
    <mat-expansion-panel-header [class.error-message]="message.type === MessageType.ERROR"
      [class.info-message]="message.type === MessageType.INFO"
      [class.system-message]="message.type === MessageType.WARNING"
      [class.dev-message]="message.type === MessageType.DEV_WARNING"
      class="expansion-message">
      <mat-panel-title>
        <div class="items-center row grow">

          @if (message.type === MessageType.INFO) {
            <icz-button primary background="transparent" size="large"
            svgIcon="success"></icz-button>
          }
          @if (message.type === MessageType.ERROR) {
            <icz-button primary background="transparent" size="large"
            svgIcon="error"></icz-button>
          }
          @if (message.type === MessageType.WARNING) {
            <icz-button primary background="transparent" size="large"
            svgIcon="progress"></icz-button>
          }

          <icz-notification-template-outlet
            [ngClass]="contentHeaderClass"
            [template]="message.data!.header.template"
            [templateContext]="$any(message.data!.header).templateData"
            [templateContextSchema]="$any(message.data!.header).templateDataSchema"
            (linkClicked)="onClose()"
          ></icz-notification-template-outlet>

          @if (showExpandButton) {
            <icz-button
              [class.expand-arrow-expanded]="expansionPanel.expanded"
              primary background="transparent" class="expand-arrow"
              svgIcon="page_arrow"
            ></icz-button>
          }
          @if (showCloseButton) {
            <icz-button
              (onAction)="onClose($event)"
              primary background="transparent" svgIcon="close"
            ></icz-button>
          }
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @if (message.data!.body) {
      <div class="toast-message-body">
        <div class="gap-8 col">
          @if (isMessageTemplateArray(message.data!.body)) {
            @for (bodyPart of message.data!.body; track bodyPart) {
              <icz-notification-template-outlet
                [template]="bodyPart.template"
                [templateContext]="$any(bodyPart).templateData"
                [templateContextSchema]="$any(bodyPart).templateDataSchema"
                class="toast-message-body-part"
              ></icz-notification-template-outlet>
            }
          }
          @else {
            <icz-notification-template-outlet
              [template]="$any(message.data!.body).template"
              [templateContext]="$any(message.data!.body).templateData"
              [templateContextSchema]="$any(message.data!.body).templateDataSchema"
            ></icz-notification-template-outlet>
          }
        </div>
      </div>
    }
    @if (message.data!.buttons?.length) {
      <div class="px-24">
        @if (message.data!.body) {
          <hr class="col-splitter">
        }
        <br>
          <div class="row">
            <icz-button-collection
              [buttons]="message.data!.buttons!"
              (buttonClicked)="processButtonAction($event)">
            </icz-button-collection>
          </div>
        </div>
      }
    </mat-expansion-panel>
  </mat-accordion>
