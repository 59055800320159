import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {AbstractFormField, IczOption, locateOptionByValue} from '@icz/angular-form-elements';
import {AddressCompleteDto, AddressWithTypeAndValidityDto, AddressWithTypeDto} from '../../model/addresses.model';
import {hashed, IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';

export interface SubjectAddressOptionData {
  addressWithType: AddressWithTypeDto;
}

@Component({
  selector: 'icz-subject-address-selector',
  templateUrl: './subject-address-selector.component.html',
  styleUrls: ['./subject-address-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectAddressSelectorComponent extends AbstractFormField<AddressCompleteDto> implements IczOnChanges {
  @Input()
  override set value(newValue: Nullable<AddressCompleteDto>) {
    this.__value = newValue ? hashed(newValue) : null;
  }
  override get value(): Nullable<AddressCompleteDto> {
    return this.getAddressObjectByValue();
  }

  @Input({required: true})
  subject!: SubjectRecordDto;
  @Input()
  withFirstAddressPreselection = false;
  @Input()
  additionalAddresses!: AddressWithTypeAndValidityDto[];
  @Input()
  showValidationStatus = true;

  options: IczOption<string, SubjectAddressOptionData>[] = [];

  __value: Nullable<string>;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.subject || changes.additionalAddresses) {
      this.buildOptionsFromInputs();

      if (this.withFirstAddressPreselection) {
        this._valueChanged(this.options[0]!.value);
      }
    }
  }

  private buildOptionsFromInputs() {
    if (this.subject) {
      const typedSubjectAddresses: AddressWithTypeAndValidityDto[] = SubjectTemplateUtils.getBusinessSortedAddresses(this.subject, true).map(address => ({
        ...address,
        isCurrentlyValid: true,
      }));

      this.options = [
        ...typedSubjectAddresses,
        ...(this.additionalAddresses ?? []),
      ].map(addressWithTypeAndValidity => ({
        value: hashed(addressWithTypeAndValidity.address),
        disabled: !addressWithTypeAndValidity.isCurrentlyValid,
        label: SubjectTemplateUtils.getAddressString(addressWithTypeAndValidity, this.subject.classification),
        data: {
          addressWithType: addressWithTypeAndValidity,
        }
      }));
    }
  }

  _valueChanged(addressHash: Nullable<string>) {
    this.__value = addressHash;
    this.valueChange.emit(this.getAddressObjectByValue());
  }

  private getAddressObjectByValue() {
    return locateOptionByValue(this.options, this.__value)?.data?.addressWithType?.address;
  }
}
