import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export const animationDuration = 300;
export enum AnimationState {
  SHAKESTART = 'shakestart',
  SHAKEEND = 'shakeend'
}

export const shakeit = trigger('shakeit', [
  state(AnimationState.SHAKESTART, style({
    transform: 'scale(1)',
  })),
  state(AnimationState.SHAKEEND, style({
    transform: 'scale(1)',
  })),
  transition(`${AnimationState.SHAKESTART} => ${AnimationState.SHAKEEND}`, animate(`${animationDuration}ms ease-in`, keyframes([
    style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
    style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
    style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
    style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
    style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
    style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
    style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
    style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
    style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
  ]))),
]);

export const appearWithDelay = trigger('appearWithDelay', [
  transition(':enter', [
    style({opacity: 0}),
    animate('100ms', style({opacity: 0})),
    animate('101ms', style({opacity: 1})),
  ])
]);
