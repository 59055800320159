import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {ConsignmentType} from '|api/commons';
import {
  GenericOwnElasticConsignmentWithConsignee,
  isOwnDigitalElasticConsignment,
  isOwnFaxElasticConsignment,
  isOwnInternalElasticConsignment,
  isOwnMultiPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment,
  isOwnPersonalElasticConsignment,
  isOwnPortalElasticConsignment
} from '../../model/own-consignment-model';
import {AddressCompleteDto, addressToAddressWithType, AddressWithTypeDto} from '../../../model/addresses.model';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {SheetNodeDto} from '|api/sad';

@Component({
  selector: 'icz-consignment-table-address',
  templateUrl: './consignment-table-address.component.html',
  styleUrls: ['./consignment-table-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentTableAddressComponent implements OnInit {

  private codebookService = inject(CodebookService);

  @Input({required: true})
  consignment!: GenericOwnElasticConsignmentWithConsignee;

  sheetNodes: SheetNodeDto[] = [];

  protected readonly isOwnInternalElasticConsignment = isOwnInternalElasticConsignment;
  protected readonly isOwnPaperElasticConsignment = isOwnPaperElasticConsignment;
  protected readonly isOwnOfficeDeskElasticConsignment = isOwnOfficeDeskElasticConsignment;
  protected readonly isOwnDigitalElasticConsignment = isOwnDigitalElasticConsignment;
  protected readonly isOwnFaxElasticConsignment = isOwnFaxElasticConsignment;
  protected readonly isOwnMultiPaperElasticConsignment = isOwnMultiPaperElasticConsignment;

  readonly ConsignmentType = ConsignmentType;

  ngOnInit() {
    this.codebookService.sheetNodes().subscribe(sheetNodesResult => {
      this.sheetNodes = sheetNodesResult;
    });
  }

  addressToAddressWithType(consigneeAddress: AddressCompleteDto): AddressWithTypeDto {
    return addressToAddressWithType(consigneeAddress);
  }

  filingOfficeAddress(filingOfficeId: number): Nullable<AddressWithTypeDto> {
    const filingNode = this.sheetNodes.find(sn => sn.id === filingOfficeId);
    if (filingNode && filingNode.address) {
      return addressToAddressWithType(filingNode.address as AddressCompleteDto);
    } else {
      return null;
    }
  }

  protected readonly isOwnPersonalElasticConsignment = isOwnPersonalElasticConsignment;
  protected readonly isOwnPortalElasticConsignment = isOwnPortalElasticConsignment;
}
