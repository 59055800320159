import {animate, style, transition, trigger} from '@angular/animations';
import {CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef,
  DoCheck,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatSort, MatSortable, MatSortHeader, Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ResizableModule, ResizeEvent} from 'angular-resizable-element';
import {cloneDeep, isEqual} from 'lodash';
import {BehaviorSubject, combineLatest, merge, Observable, skip, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, take, withLatestFrom} from 'rxjs/operators';
import {
  AutoPageSizeByManualHeight,
  AutoPageSizeByOffsets,
  AutoPageSizeConfig,
  ColumnDefinition,
  ResizeLineBounds,
  TableConfig,
  TableConfigExtensions,
  TablePage,
  TableTemplates
} from './table.models';
import {getDefaultTableToolbarConfig, TableToolbarComponent} from './table-toolbar/table-toolbar.component';
import {
  areTableRowKeysEqual,
  areTableRowsEqual,
  RowDisableFn,
  RowKeyFn,
  SelectedRowsService,
  TableRow
} from './selected-rows.service';
import {CustomPaginator, TablePaginatorComponent} from './table-paginator/table-paginator.component';
import {TableToolbarService} from './table-toolbar/table-toolbar.service';
import {TableColumnsData} from './table-columns-data';
import {ColumnTemplateDirective} from './column-template.directive';
import {
  ButtonComponent,
  DetachingService,
  hashed,
  IconComponent,
  IczOnChanges,
  IczSimpleChanges,
  InaccessibleDirective,
  InterpolatePipe,
  LabelComponent,
  LinkWithoutHrefDirective,
  NewspaperLoaderComponent,
  PopoverComponent,
  TooltipDirective
} from '@icz/angular-essentials';
import {CheckboxComponent, PrimitiveControlValueType} from '@icz/angular-form-elements';
import {IczTableDataSource} from './table.datasource';
import {CodebookFilterDefinition, EnumFilterDefinition, FilterItemValue, FilterListOption} from './filter.types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModalDialogComponent} from '@icz/angular-modal';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {
  EMPTY_FILTER_TREE,
  FilterItemTree,
  filterItemTreeToFilterItemValueTree,
  FilterParamTree,
  FilterTreeOperator,
  isFilterTreeEmpty,
  isSimpleQueryFilterTree,
  viewFilterTreeToDataFilterTree
} from './filter-trees.utils';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatProgressBar} from '@angular/material/progress-bar';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRecycleRows,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import {TableHeaderCellComponent} from './table-header-cell/table-header-cell.component';
import {TableDataCellComponent} from './table-data-cell/table-data-cell.component';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {TableContextMenuComponent} from './table-context-menu/table-context-menu.component';
import {FilterOperator, FilterParam, SearchParams, SortParam} from './table.utils';
import {
  EsslTableQueryParametersSerializationMode,
  PersistedColumnProperties,
  PersistedUserTableProperties,
  SAVED_FILTERS_PERSISTOR,
  TABLE_PROPERTIES_PERSISTOR,
  TABLE_QUERY_PARAMS_SERIALIZER
} from './table.providers';
import {FULLTEXT_SEARCH_TERM_FILTER_ID} from './table-toolbar/saved-filters.service';

/**
 * Gets full default table configuration object.
 */
export function getDefaultTableConfig(): TableConfig<never> {
  return {
    hasActiveRow: false,
    defaultFilterColumns: [],
    defaultSort: null,
    hoverableRows: true,
    rowHeight: 36,
    autoPageSizeConfig: true,
    allowMultiPageSelection: true,
    disableLocalStorageSortPersistence: false,
    toolbarConfig: getDefaultTableToolbarConfig(),
  };
}

/**
 * Used for patching default table config with custom config extensions.
 */
export function extendDefaultTableConfig<TColumnKey extends string>(configExtensions: TableConfigExtensions<TColumnKey>): TableConfig<TColumnKey> {
  const defaultConfig = getDefaultTableConfig();

  return {
    ...defaultConfig,
    ...configExtensions,
    toolbarConfig: {
      ...defaultConfig.toolbarConfig,
      ...(configExtensions.toolbarConfig ?? {})
    }
  };
}

/**
 * Upper limit for page size set due to performance reasons.
 */
const PAGE_SIZE_LIMIT = 200;
/**
 * Auto page size determined at runtime should be a multiple of this number.
 */
const ROW_COUNT_MULTIPLE = 1;
/**
 * Height of paginator and table toolbar in px.
 */
const TABLE_TOOLBAR_HEIGHT = 52;
/**
 * Height of <th> element in table in px, copied from $table-th-height.
 */
const TABLE_HEADER_HEIGHT = 42;

/**
 * Default row key generator - rows will be distinguished by their IDs.
 * @see RowKeyFn
 */
const ROW_KEY_BY_ID_FN = (row: TableRow) => row.id;

/**
 * @internal
 */
export const TABLE_IMPORTS = [
  TableToolbarComponent,
  CdkOverlayOrigin,
  MatProgressBar,
  InaccessibleDirective,
  MatSort,
  CdkDropList,
  MatSortHeader,
  ResizableModule,
  TableHeaderCellComponent,
  CheckboxComponent,
  NewspaperLoaderComponent,
  TableDataCellComponent,
  NgTemplateOutlet,
  LinkWithoutHrefDirective,
  IconComponent,
  LabelComponent,
  TablePaginatorComponent,
  PopoverComponent,
  TableContextMenuComponent,
  AsyncPipe,
  InterpolatePipe,
  TranslateModule,
  TooltipDirective,
  NgClass,
  MatTable,
  MatRecycleRows,
  MatHeaderCellDef,
  MatHeaderRowDef,
  MatColumnDef,
  MatCellDef,
  MatRowDef,
  MatHeaderCell,
  MatCell,
  MatHeaderRow,
  MatRow,
  ButtonComponent,
];

/**
 * @internal
 */
export const TABLE_PROVIDERS = [
  {
    provide: MatPaginatorIntl,
    useClass: CustomPaginator,
  },
  SelectedRowsService,
  TableToolbarService,
];

/**
 * @internal
 */
export const TABLE_HOST_CONTENTS = {
  'class': 'icz-table',
};

/**
 * @internal
 */
const TABLE_ROW_EXPAND_ANIMATION = trigger('rowExpandAnimation', [
  transition(':enter', [
    style({height: 0, opacity: 0}),
    animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({height: '*', opacity: 1})),
  ]),
  transition(':leave', [
    style({height: '*', opacity: 1}),
    animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({height: 0, opacity: 0})),
  ]),
]);

/**
 * Reserved column names of table; for common table columns
 * which are implemented directly by the table.
 */
export enum TableReservedColumns {
  SELECTION = 'selection',
}

/**
 * @internal
 */
const RESERVED_COLUMNS_VALUES = Object.values(TableReservedColumns) as string[];

/**
 * A complex data grid which supports:
 * - Filtering by fulltext query,
 * - Filtering by simple logical formulas,
 * - Sorting by table columns (only single, non-cascaded sort),
 * - Ability to resize and reorder columns for each business table view,
 * - Ability to create user-defined columnset for each business table view,
 * - Persistence of user-defined filters, sorts and columnset into a storage,
 * - Serialization and deserialization of query parameters which denote user-defined filtering and sorting.
 */
@Component({
  animations: [TABLE_ROW_EXPAND_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: TABLE_IMPORTS,
  providers: TABLE_PROVIDERS,
  host: TABLE_HOST_CONTENTS,
  selector: 'icz-table',
  standalone: true,
  styleUrls: ['./table.component.scss'],
  templateUrl: './table.component.html',
})
export class TableComponent<TColumnKey extends string> implements OnInit, AfterViewInit, AfterContentInit, IczOnChanges, DoCheck {

  tableToolbarService = inject(TableToolbarService);
  protected selectedRowsService = inject(SelectedRowsService);
  protected tablePropertiesPersistor = inject(TABLE_PROPERTIES_PERSISTOR);
  protected savedFiltersPersistor = inject(SAVED_FILTERS_PERSISTOR);
  protected router = inject(Router);
  protected cd = inject(ChangeDetectorRef);
  private translate = inject(TranslateService);
  private detachingService = inject(DetachingService);
  private ngZone = inject(NgZone);
  private destroyRef = inject(DestroyRef);
  private activatedRoute = inject(ActivatedRoute);
  private queryParamsSerializer = inject(TABLE_QUERY_PARAMS_SERIALIZER);
  private modalComponent = inject(ModalDialogComponent, {optional: true});

  protected readonly TableReservedColumns = TableReservedColumns;
  protected readonly MAT_HEADER_ID = 'matheader';
  protected readonly SELECTION_COLUMN_WIDTH = 56; // For desired 20px padding left and right + 16px checkbox itself
  protected readonly COLUMN_WIDTH_FALLBACK = 100;

  /**
   * Table ID. Should be unique for each business data view.
   */
  @Input({required: true})
  id!: string;
  /**
   * Table data source. Can fetch data either synchronously or asynchronously.
   */
  @Input({required: true})
  dataSource: Nullable<IczTableDataSource<any>>;
  /**
   * Table column schema definition with filter definitions.
   */
  @Input({required: true})
  columnsData: Nullable<TableColumnsData<TColumnKey|TableReservedColumns>>;
  /**
   * Table row objects to have preselected checkboxes on table init.
   */
  @Input()
  preselectedRows: Nullable<any[]>;
  /**
   * New extra table row which will be displayed under a table row which initiated the expansion.
   */
  @Input()
  expandedRow!: any;
  /**
   * Configuration of various small UI aspects of the table.
   */
  @Input()
  config: TableConfig<TColumnKey> = getDefaultTableConfig();
  /**
   * Array of column IDs whose filters will be usable. null means "enable all filters".
   */
  @Input()
  enabledFilters: Nullable<string[]>;
  /**
   * Row key generator, used in various row comparation use-cases (checkboxes, active row, focused row, etc.)
   * It is important to note that each row in the view must be able to consistently produce its key
   *  - (i.e. very heterogenous data with no single property in common might be unsuitable for table viewing)
   * @see RowKeyFn
   */
  @Input()
  rowKeyFn: RowKeyFn<any, unknown> = ROW_KEY_BY_ID_FN;
  /**
   * Disables row checkbox selection.
   * @see RowDisableFn
   */
  @Input()
  rowSelectionDisabler: Nullable<RowDisableFn<any>>;
  /**
   * Disables row clicking and activation.
   * @see RowDisableFn
   */
  @Input()
  rowDisabler: Nullable<RowDisableFn<any>>;
  /**
   * Sets search term visible in table filtering bar in fulltext search field.
   */
  @Input()
  searchTerm: string = '';
  /**
   * Shows a subtle indefinite progress bar at the top of the table.
   */
  @Input()
  isNonBlockingLoading = false;
  /**
   * Used for setting a specific text explaining why a table row can not be checkbox selected.
   * Effective only if rowSelectionDisabler is defined.
   */
  @Input()
  disabledRowTooltip: Nullable<string>;
  /**
   * Enables a special button for selecting all items in the entire data view.
   * Filtering the table and then clicking global select all will select only the filtered subset of the view..
   * (checkbox selecting all data on all pages might be time consuming)
   */
  @Input()
  enableGlobalSelectAll = false;
  /**
   * If true, disables query string filter+sort serialization/deserialization.
   * Used when there are two or more tables at the same time on the screen.
   */
  @Input()
  disableUrlParameters = false;
  /**
   * If true, table filters can be set as placeholders without values to be filled by the users
   * later. If false, the user must fill out all filters in order to be able to save it.
   */
  @Input()
  allowSavingFiltersWithEmptyValues = true;
  /**
   * Manual override for user defined row count, used to keep table height under control if
   * the table is placed in a scrollable container with other container contents.
   */
  @Input()
  manualRowCount: Nullable<number>;
  /**
   * Hides the entire table toolbar section above table data area header row.
   */
  @Input()
  hideTableToolbar = false;

  /**
   * Emits fulltext search term everytime it it changed.
   */
  @Output()
  searchTermChange = new EventEmitter<string>();
  /**
   * Emits a row object corresponding to a row which was clicked in the table.
   */
  @Output()
  rowClicked = new EventEmitter<any>();
  /**
   * Fired after table view init, emits table component instance object.
   */
  @Output()
  initialized = new EventEmitter<this>();
  /**
   * Fired when the user ends resizing a column.
   */
  @Output()
  resized = new EventEmitter<void>();
  /**
   * Fired when the user ends dragging a column.
   */
  @Output()
  dragged = new EventEmitter<void>();
  /**
   * Emits a table row object which was activated by the user.
   * If the table row was deactivated by the user, emits null.
   */
  @Output()
  activeRowChanged = new EventEmitter<any>();
  /**
   * If using filters with logical structures using parentheses, this output
   * fires when user requests to edit the filter logical formula.
   */
  @Output()
  editQueryRequested = new EventEmitter<void>();
  /**
   * Fired on each change of checkbox selected rows set.
   * If nothing is selected, emits empty array.
   */
  @Output()
  selectedRowsChanged = new EventEmitter<any>();
  /**
   * Fired after each page/filter/sort request begins (i.e. page data are not available yet).
   */
  @Output()
  pageLoadStarted = new EventEmitter<SearchParams>();
  /**
   * Effective only if @Input enableGlobalSelectAll is set to TRUE.
   * Emits a search query which corresponds to all selected items.
   */
  @Output()
  searchParamsSelected = new EventEmitter<SearchParams>();

  /**
   * Fired after each change of filters
   */
  @Output()
  activeFiltersChanged = new EventEmitter<FilterItemTree>();

  private sorted = new Subject<Nullable<Sort>>();
  protected pageChanged = new Subject<PageEvent>();

  private sortFields: SortParam<TColumnKey>[] = [];
  private filterParams: FilterParamTree = EMPTY_FILTER_TREE;
  private activeFilters: FilterItemTree = EMPTY_FILTER_TREE;
  private defaultColumnDefinitions: ColumnDefinition<TColumnKey>[] = [];

  protected hoveredHeader: Nullable<string>;
  protected customTableCells: TableTemplates = {};
  protected isResizing = false;
  protected tableWidthIsAutomatic$ = new BehaviorSubject(true);
  protected resizeLineBounds: ResizeLineBounds = {x: 0, y: 0, height: 0};

  private selectedRows: Array<{ id: number }> = [];
  private focusedRowKey: any;
  private isTableInitialized = false;
  protected isGlobalSelected = false;

  // Column ID -> CSS Width Value
  protected columnWidths: Record<string, string> = {};
  protected tableWidth: string = 'auto';

  protected showContextMenu$ = new BehaviorSubject(false);
  protected toolbarOpened = false;

  @ViewChild(TablePaginatorComponent, {static: true})
  protected tablePaginator!: TablePaginatorComponent;
  @ViewChild(MatSort, {static: true})
  protected matSort!: MatSort;
  @ViewChild('tableContainerEl', {read: ElementRef, static: false})
  tableContainerEl!: ElementRef;
  @ViewChild('tableEl', {read: ElementRef, static: false})
  protected tableEl!: ElementRef;
  @ViewChild(TableToolbarComponent)
  protected tableToolbar!: TableToolbarComponent;
  @ContentChildren(ColumnTemplateDirective)
  protected columnsTemplates!: QueryList<ColumnTemplateDirective<any, any>>;
  @ContentChild('iczExpandedRowTemplate')
  protected expandedRowTemplate!: TemplateRef<any>;

  protected currentSort: Nullable<Sort>;
  protected currentPage: Nullable<TablePage>;

  protected hasNoData$!: Observable<boolean>;
  protected emptyDataSubsetFound$!: Observable<boolean>;

  protected tablePageRowCount!: number;
  private rowSelectionDisablingFnChanges$: BehaviorSubject<Nullable<RowDisableFn>> = new BehaviorSubject<Nullable<RowDisableFn>>(null);

  protected globalSelectionTextInfo = 'Jsou vybrané všechny záznamy (celkem {{pageCount}}) na této stránce.';
  protected globalSelectionTextLink = 'Vybrat všechny nalezené záznamy (celkem {{totalCount}})';
  protected globalSelectionSelectedtext = 'Vybrané všechny nalezené záznamy (celkem {{totalCount}})';

  private rowSelectionSub = new Subscription();
  private activeRowSub = new Subscription();
  private filterOrSortChangeSub = new Subscription();
  private itemsSub = new Subscription();
  private listLoadingStatesSubscription: Nullable<Subscription>;
  private dataSourceConnectSubscription: Nullable<Subscription>;
  private queryParamsSubscription: Nullable<Subscription>;

  // used for manual list$ changes handling
  private asyncListObservables: Record<string, Observable<FilterListOption[]>> = {};

  private filterChanged$ = this.pageLoadStarted.pipe(
    map(searchParams => searchParams.filter),
    distinctUntilChanged(isEqual),
  );

  protected get listLoadingStates() {
    return this.columnsData!.listLoadingStates;
  }

  protected get maxContextMenuHeight(): number {
    return TABLE_TOOLBAR_HEIGHT + this.getContentSpaceHeight(this.config.autoPageSizeConfig);
  }

  protected get showGlobalSelection() {
    return this.enableGlobalSelectAll &&
      this.selectedRowsService.isAllOnPageSelected() &&
      (this.dataSource!.paginator!.pageSize < this.dataSource!.paginator!.length);
  }

  private get tableSchemaVersionHash(): string {
    if (this.columnsData) {
      const columnPropertiesForHashing = this.columnsData.columnDefinitions.map(
        cd => `${cd.id}${cd.filterConfig?.customFilterId}${cd.filterType}${cd.disableSort}`
      );
      columnPropertiesForHashing.sort();
      return hashed({hash: columnPropertiesForHashing.join(',')});
    }
    else {
      return 'unknown';
    }
  }

  /*
   * We do not want to interact with url parameters when table is in modal or at a route without dedicated history bit
   * or has a configuration input flipped to true (that might happen when there is multiple tables on the same page).
   */
  private get shouldBlockUrlParamsProcessing() {
    return (
      this.modalComponent ||
      this.activatedRoute.snapshot.data?.['fallbackBreadcrumbUri'] ||
      this.activatedRoute.snapshot.data?.['skipHistoryBit'] ||
      this.disableUrlParameters
    );
  }

  /**
   * @internal
   */
  ngOnInit() {
    if (this.manualRowCount) {
      this.tablePageRowCount = this.manualRowCount;
    } else {
      this.tablePageRowCount = this.tablePropertiesPersistor.getTablePageRowCount();
    }

    if (this.columnsData && this.dataSource) {
      this.defaultColumnDefinitions = this.cloneColumnDefinitions(this.columnsData.initialDefinitions);

      if (this.columnsData.columnDefinitions.length === 0) {
        console.warn('There are 0 definitions of table columns');
      }

      this.loadAsyncColumnLists();
      this.loadPropertiesFromLocalStorage();
      this.connectDatasourceStateToPaginator();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());

      this.initializeTableToolbar();

      this.loadFiltersAndSort();
      this.setUpSort();
      this.pageChanged.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_page => {
        this.loadPage();
        this.tableContainerEl.nativeElement.scrollTop = 0;
        this.selectedRowsService.unsetActiveRow();
      });

      this.sorted.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(sort => {
        if (!sort) {
          this.sortFields = [];
        }
        else {
          if (sort.direction !== '') {
            // If columnDefinition has customId for filter, this ID should be used also for sorting

            this.sortFields = [
              {
                fieldName: this.getSortId(sort) as TColumnKey,
                descending: sort.direction === 'desc',
              },
            ];
          }
          // default sort should be overridable using sort indicator arrow
          else if (sort.active !== this.config.defaultSort?.fieldName) {
            this.setUpSort();
          }
        }

        this.loadPage();
        this.selectedRowsService.deselectAll();
      });

      this.tableToolbarService.reloadFilters$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => {
        this.reloadTable();
        this.cd.detectChanges();
      });

      this.tableToolbarService.activeFilters$.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(activeFilterValues => {
        this.activeFiltersChanged.emit(activeFilterValues);

        if (this.enableGlobalSelectAll) {
          if (this.dataSource!.paginator) {
            setTimeout(() => {
              this.searchParamsSelected.emit(this.getCurrentSearchParams());
            }, 0);
          }
        }
      });

      this.initDataStatusObservables();
    } else {
      throw new Error('@Input columnsData or @Input dataSource were not supplied to icz-table.');
    }
  }

  /**
   * @internal
   */
  ngDoCheck() {
    if (this.columnsData) {
      const columnIdsWithListsToReload: string[] = [];

      for (const column of this.columnsData.columnDefinitions) {
        if ((column as CodebookFilterDefinition | EnumFilterDefinition).list$ !== this.asyncListObservables[column.id]) {
          columnIdsWithListsToReload.push(column.id);
        }
      }

      if (columnIdsWithListsToReload.length) {
        this.loadAsyncColumnLists(columnIdsWithListsToReload);
      }
    }
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.columnsData && changes.columnsData.previousValue && changes.columnsData.currentValue) {
      this.listLoadingStatesSubscription?.unsubscribe();
      this.asyncListObservables = {};

      this.columnsData!.columnDefinitions.forEach(column => {
        if (column.id === TableReservedColumns.SELECTION) column.fixedWidth = this.SELECTION_COLUMN_WIDTH;
      });
      if (this.columnsData!.columnDefinitions.some(cd => cd.allowSettingInContextMenu === false && cd.displayed === true)) {
        throw new Error('Table column configuration error. Column that is displayable must be configurable in context menu as well.');
      }

      this.defaultColumnDefinitions = this.cloneColumnDefinitions(this.columnsData!.initialDefinitions);

      this.loadAsyncColumnLists();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());

      this.initializeTableToolbar();
    }

    if (changes.dataSource && changes.dataSource.previousValue && changes.dataSource.currentValue) {
      this.connectDatasourceStateToPaginator();
      this.clearSearchParams();
      this.toolbarOpened = false;
      this.initializeTableToolbar();
      this.loadPropertiesFromLocalStorage();
      this.loadFiltersAndSort();
      this.setUpSort();
      this.reinitDatasource();
    }

    if ((changes.id && changes.id.previousValue && changes.id.currentValue) || (changes.columnsData && changes.columnsData.previousValue && changes.columnsData.currentValue)) {
      this.columnWidths = {};
      this.tableWidth = 'auto';
      this.toolbarOpened = false;
      this.clearSearchParams();
      if (this.isTableInitialized) this.recoverColumnWidthsFromLocalStorage();
      this.initializeTableToolbar();
      this.loadPropertiesFromLocalStorage();
      this.loadFiltersAndSort();
    }

    if (changes.preselectedRows && !isEqual(changes.preselectedRows.currentValue, changes.preselectedRows.previousValue)) {
      this.applyPreselection();
    }

    if (changes.rowSelectionDisabler) {
      this.rowSelectionDisablingFnChanges$.next(changes.rowSelectionDisabler.currentValue);
      this.initSelectedRowsService();
      this.applyPreselection();
    }
  }

  /**
   * @internal
   */
  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator!.pageSize = this.getTablePageSize(this.config.autoPageSizeConfig);
    }

    this.autoloadDataIfPossible();

    this.recoverColumnWidthsFromLocalStorage();
    this.initialized.emit(this);
    this.isTableInitialized = true;
  }

  /**
   * @internal
   */
  ngAfterContentInit() {
    this.columnsTemplates.toArray().reduce((dic, template) => {
      dic[template.id] = {
        template: template.content,
        withEllipsis: template.withEllipsis as boolean,
      };
      return dic;
    }, this.customTableCells);

    if (this.dataSource) {
      this.dataSource.paginator = this.tablePaginator.paginator;
    }

    this.initSelectedRowsService();
  }

  /**
   * @internal
   */
  reloadTable() {
    const filtersFromToolbar = this.tableToolbarService.filters$.value.filter(
      f => (
        (f.filterOption?.value && f.value) ||
        f.filterOption?.value === FilterOperator.empty ||
        f.filterOption?.value === FilterOperator.notEmpty
      )
    );

    if (isSimpleQueryFilterTree(this.tableToolbarService.activeFilterValues$.value)) {
      this.tableToolbarService.activeFilterValues$.next(filterItemTreeToFilterItemValueTree({
        operator: FilterTreeOperator.NONE,
        values: filtersFromToolbar,
      }));
    }

    this.tableToolbarService.activeFilters$.pipe(
      take(1),
    ).subscribe(activeFilters => {
      this.filterParams = viewFilterTreeToDataFilterTree(activeFilters, this.translate.currentLang);
      this.activeFilters = activeFilters;

      this.patchHistoryBit();

      this.tablePaginator.setPage(0);
      this.loadPage();
    });
  }

  /**
   * Resets table filtering state.
   */
  clearSearchParams() {
    this.currentSort = null;
    this.sortFields = [];
    this.searchTerm = ' ';
    this.filterParams = EMPTY_FILTER_TREE;
    this.activeFilters = EMPTY_FILTER_TREE;
    this.tablePaginator.setPage(0);
  }

  /**
   * Programmatically deactivates an active row.
   */
  unsetActiveRow() {
    this.selectedRowsService.unsetActiveRow();
  }

  setFocusedRow(row: any) {
    this.focusedRowKey = this.rowKeyFn(row);

    if (this.shouldBlockUrlParamsProcessing) return;

    this.queryParamsSerializer.serialize(
      EsslTableQueryParametersSerializationMode.FOCUSED_ROW_ONLY,
      this.searchTerm,
      this.activeFilters,
      this.currentSort,
      this.currentPage,
      this.focusedRowKey
    );
  }

  /**
   * @internal
   */
  setDisplayedColumns(saveToLocalStorage?: boolean) {
    this.columnsData!.displayedColumns = this.columnsData!.columnDefinitions.filter(c => c.displayed).map(c => c.id);

    if (saveToLocalStorage) {
      this.saveTableColumnsToLocalStorage();
    }
  }

  private getSortId(sort: Sort): string {
    const customId = this.columnsData?.columnDefinitions?.find(def => def.id === sort.active && def.filterConfig?.customFilterId)?.filterConfig?.customFilterId;
    return Boolean(customId) ? customId! : sort.active;
  }

  private loadDisplayedColumnsFromLocalStorage(propertiesFromLocalStorage: Nullable<PersistedUserTableProperties>) {
    const columnsFromLocalStorage = propertiesFromLocalStorage?.columns;
    if (!columnsFromLocalStorage?.length) {
      this.tableWidthIsAutomatic$.next(true);
      return;
    }
    const originalColumnDefinitions = this.columnsData!.columnDefinitions;

    try {
      if (columnsFromLocalStorage.length > 0) {
        const firstColumn = columnsFromLocalStorage[0];
        if (!('id' in firstColumn) || !('isVisible' in firstColumn)) {
          throw new Error('Incompatible saved column local storage data found.');
        }
      }
      const filteredLocalStorageColumns =
        columnsFromLocalStorage.filter(lsc => this.columnsData!.columnDefinitions.some(cd => cd.id === lsc.id));

      this.columnsData!.displayedColumns = filteredLocalStorageColumns.filter(p => p.isVisible).map(p => p.id);
      this.columnsData!.columnDefinitions = this.columnsData!.columnDefinitions.map(c => {return {...c, displayed: false};});
      this.columnsData!.displayedColumns.forEach((columnId, index) => {
        const oldIndex = this.columnsData!.columnDefinitions.findIndex(cDef => cDef.id === columnId);
        const columnDef = this.columnsData!.columnDefinitions[oldIndex];
        columnDef.displayed = true;
        moveItemInArray(this.columnsData!.columnDefinitions, oldIndex, index);
      });

      const someColumnsHaveDefinedWidth = filteredLocalStorageColumns.some(c => !isNil(c.columnWidth));
      this.tableWidthIsAutomatic$.next(!someColumnsHaveDefinedWidth);
    }
    catch {
      this.columnsData!.columnDefinitions = originalColumnDefinitions;

      if (propertiesFromLocalStorage) {
        delete propertiesFromLocalStorage.columns;
        this.tablePropertiesPersistor.saveTableProperties(this.id, propertiesFromLocalStorage);
      }
    }
  }

  private loadSortFromLocalStorage(propertiesFromLocalStorage: Nullable<PersistedUserTableProperties>) {
    if (!this.config.disableLocalStorageSortPersistence) {
      const sortedColumn = propertiesFromLocalStorage?.sort;

      if (sortedColumn) {
        this.currentSort = {
          active: sortedColumn.sortColumnId,
          direction: sortedColumn.sortDirection!,
        };
      }
    }
  }

  private loadPropertiesFromLocalStorage() {
    let propertiesFromLocalStorage = this.tablePropertiesPersistor.getTableProperties(this.id);

    if (propertiesFromLocalStorage) {
      if (propertiesFromLocalStorage.tableSchemaVersionHash !== this.tableSchemaVersionHash) {
        this.tablePropertiesPersistor.deleteCustomPropertiesForTable(this.id);
        propertiesFromLocalStorage = null;
      }
    }

    this.loadDisplayedColumnsFromLocalStorage(propertiesFromLocalStorage);
    this.loadSortFromLocalStorage(propertiesFromLocalStorage);
  }

  private saveTableColumnsToLocalStorage() {
    this.ngZone.onMicrotaskEmpty.pipe(
      take(1),
    ).subscribe(() => {
      const previousProperties = this.tablePropertiesPersistor.getTableProperties(this.id);
      const previousColumns = previousProperties?.columns ?? [];
      const currentColumns: PersistedColumnProperties[] = this.columnsData!.columnDefinitions.map(cd => ({
        id: cd.id,
        isVisible: this.columnsData!.displayedColumns.includes(cd.id),
        columnWidth: (
          this.getColumnElementsByColumnId(cd.id)?.[0]?.offsetWidth ??
          previousColumns.find(p => p.id === cd.id)?.columnWidth
        ),
        sortDirection: this.currentSort?.active === cd.id ? this.currentSort?.direction : undefined,
      }));

      let valueToSave: PersistedUserTableProperties;

      if (previousProperties) {
        valueToSave = {
          ...previousProperties,
          columns: currentColumns,
        };
      }
      else {
        valueToSave = {
          tableSchemaVersionHash: this.tableSchemaVersionHash,
          sort: null,
          columns: currentColumns,
        };
      }

      this.tablePropertiesPersistor.saveTableProperties(this.id, valueToSave);
    });
  }

  private clearSettingsInLocalStorage() {
    this.tablePropertiesPersistor.saveTableProperties(
      this.id,
      {
        tableSchemaVersionHash: this.tableSchemaVersionHash,
        sort: null,
        columns: null,
      }
    );
  }

  protected onHeaderMouseover(columnId: string) {
    this.hoveredHeader = columnId;
  }

  protected onHeaderMouseleave() {
    this.hoveredHeader = null;
  }

  protected onResizeStart(event: ResizeEvent, columnId: string): void {
    const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
    if (!col || col.fixedWidth) return;
    // resizeStart is triggered on all columns that will change size, not just the user origin. Hence storing resizedColumn name only
    // if resizing isn't in progress yet
    this.isResizing = true;
  }

  protected onResizing(event: ResizeEvent, columnId: string) {
    const resizedColHeadEl = this.getColumnElementsByColumnId(columnId);
    const tableContainerEl = this.tableEl.nativeElement.parentNode;
    if (!event?.rectangle || !resizedColHeadEl?.length || !tableContainerEl) return;

    const headerRect = resizedColHeadEl[0].getBoundingClientRect();
    const tableContainerRect = tableContainerEl.getBoundingClientRect();

    this.resizeLineBounds.x = headerRect?.x! + event.rectangle.width!;
    this.resizeLineBounds.y = headerRect?.y!;
    this.resizeLineBounds.height = tableContainerRect?.height!;
  }

  protected selectAllItemsByTotalCount() {
    this.isGlobalSelected = true;
    this.searchParamsSelected.emit(this.getCurrentSearchParams());
  }

  protected clearSelection() {
    this.isGlobalSelected = false;
    this.selectedRowsService.deselectAll();
  }

  private firstTimeSetTableToManual() {
    this.columnsData!.columnDefinitions.forEach(cd => {
      if (cd.displayed) this.setTableColumnWidth(cd, this.getColumnElementsByColumnId(cd.id)?.[0]?.offsetWidth);
    });
    this.tableWidthIsAutomatic$.next(false);
    this.saveTableColumnsToLocalStorage();
  }

  protected onResizeEnd(event: ResizeEvent, columnId: string): void {
    this.detachingService.reattach();

    // let's not do anything if resize handle was clicked without moving the mouse
    if (event.edges.right === 0 || event.edges.left === 0) {
      this.isResizing = false;
      return;
    }

    if (this.tableWidthIsAutomatic$.getValue()) {
     this.firstTimeSetTableToManual();
    }

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      // otherwise, do the resizing
      if (event.edges.right) {
        const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
        if (!col || col.fixedWidth) return; // do not attempt to resize if fixedWidth
        this.setTableColumnWidth(col, event.rectangle.width!);
        if (this.columnWidths[col.id] !== 'auto') {
          this.setTableWidth({columnId, newWidth: Number(this.columnWidths[col.id].split('px')[0])});
        }
      }
      this.isResizing = false;
      this.tableWidthIsAutomatic$.next(false);
      this.resized.emit();
      this.resizeLineBounds = {x: 0, y:0, height: 0};
      this.saveTableColumnsToLocalStorage();
    });
  }

  private setTableWidth(newlyResizedCol?: {columnId: string, newWidth: number}) {
    const columnsFromLocalStorage = this.tablePropertiesPersistor.getTableProperties(this.id)?.columns;
    if (!columnsFromLocalStorage?.length) {
      return;
    }

    let tableWidth = 0;
    columnsFromLocalStorage.forEach(storedColumn => {
      if (this.columnsData!.displayedColumns.includes(storedColumn.id)) {
        tableWidth += storedColumn.columnWidth ?? 0;
      }
    });

    // If setting new table width is done *because of* user resize, the affected column saved width needs to be discarded and new width added
    if (newlyResizedCol) {
      const storedCol = columnsFromLocalStorage.find(c => c.id === newlyResizedCol.columnId);
      tableWidth -= storedCol!.columnWidth!;
      tableWidth += newlyResizedCol.newWidth;
    }

    this.tableWidth = `${tableWidth}px`;
    this.cd.detectChanges();
  }

  private setTableColumnWidth(col: ColumnDefinition<string>, newWidth: Nullable<number>) {
    if (newWidth) {
      newWidth = newWidth ?? this.COLUMN_WIDTH_FALLBACK;
      let cssValue = `${newWidth}px`;

      if (col.maxWidth && newWidth > col.maxWidth) {
        cssValue = `${col.maxWidth}px`;
      }
      if (col.minWidth && newWidth < col.minWidth) {
        cssValue = `${col.minWidth}px`;
      }

      this.columnWidths[col.id] = cssValue;
    } else {
      if (col.fixedWidth) {
        this.columnWidths[col.id] = `${col.fixedWidth}px`;
      } else if (this.tableWidthIsAutomatic$.value) {
        this.columnWidths[col.id] = 'auto';
      } else {
        this.columnWidths[col.id] = `${this.COLUMN_WIDTH_FALLBACK}px`;
      }
    }

    this.cd.detectChanges();
  }

  private getColumnElementsByColumnId(columnId: string): HTMLElement[] {
    return Array.from(this.tableEl.nativeElement.querySelectorAll(
      `.mat-column-${columnId.replace('.', '-')}`)) as HTMLElement[];
  }

  toggleColumn(columnId: string) {
    if (this.tableWidthIsAutomatic$.value) {
      this.firstTimeSetTableToManual();
    }

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
      if (!col) return;
      col.displayed = !col.displayed;
      this.setDisplayedColumns();
      this.dragged.emit();

      this.ngZone.onMicrotaskEmpty.pipe(
        take(1)
      ).subscribe(() => {
        this.recoverColumnWidthsFromLocalStorage();
        this.saveTableColumnsToLocalStorage();
      });
    });
  }

  protected resetUserAdjustmentsToDefault() {
    this.columnsData!.columnDefinitions = this.cloneColumnDefinitions(this.defaultColumnDefinitions)
      .map(columnDefinition => ({
        ...columnDefinition,
        list: (this.columnsData!.columnDefinitions.find(
          cd => cd.id === columnDefinition.id
        ) as EnumFilterDefinition | CodebookFilterDefinition).list,
      }));
    this.setDisplayedColumns();

    this.tableWidthIsAutomatic$.next(true);

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      for (const displayedColumnId of this.columnsData!.displayedColumns) {
        const displayedColumnDef = this.columnsData!.columnDefinitions.find(cd => cd.id === displayedColumnId)!;
        this.setTableColumnWidth(displayedColumnDef, null);
      }

      this.sortChanged(
        this.config.defaultSort ?
          {
            active: this.config.defaultSort.fieldName,
            direction: this.config.defaultSort.descending ? 'desc' : 'asc'
          } :
          null
      );

      this.clearSettingsInLocalStorage();
    });
  }

  protected dropListDropped(event: CdkDragDrop<HTMLElement, HTMLElement>) {
    const hasSelectionColumn = this.columnsData!.columnDefinitions.find(c => c.id === TableReservedColumns.SELECTION);
    const shiftIndex = hasSelectionColumn ? 1 : 0;
    const getActualPreviousIndex = () => {
      const columnId = this.columnsData!.displayedColumns[event.previousIndex + shiftIndex];
      return this.columnsData!.columnDefinitions.findIndex(c => c.id === columnId);
    };

    const getActualIndexOfDroppedInto = () => {
      const columnId = this.columnsData!.displayedColumns[event.currentIndex + shiftIndex];
      return this.columnsData!.columnDefinitions.findIndex(c => c.id === columnId);
    };

    if (event) {
      moveItemInArray(this.columnsData!.columnDefinitions, getActualPreviousIndex(), getActualIndexOfDroppedInto());
      this.setDisplayedColumns();
      this.recoverColumnWidthsFromLocalStorage();
      this.saveTableColumnsToLocalStorage();
    }
    this.dragged.emit();
  }

  private recoverColumnWidthsFromLocalStorage() {
    const columnsFromLocalStorage = this.tablePropertiesPersistor.getTableProperties(this.id)?.columns ?? [];

    this.columnsData!.displayedColumns.forEach(columnId => {
      const columnDef = this.columnsData!.columnDefinitions.find(cDef => cDef.id === columnId);
      const savedColumnWidth = columnsFromLocalStorage.find(p => p.id === columnId)?.columnWidth ?? null;

      if (columnDef) {
        this.setTableColumnWidth(columnDef, savedColumnWidth);
      }
    });
    this.setTableWidth();
  }

  private reinitDatasource() {
    this.dataSource!.paginator = this.tablePaginator.paginator;
    this.initSelectedRowsService();
    this.applyPreselection();
    this.initDataStatusObservables();
    this.selectedRowsService.deselectAll();
    this.autoloadDataIfPossible();
  }

  private loadFiltersAndSort() {
    this.tableToolbarService.filters$.pipe(take(1)).subscribe(filters => {
      this.queryParamsSubscription?.unsubscribe();
      this.queryParamsSubscription = this.activatedRoute.queryParams.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(queryParams => {
        let hasFilters = false;

        const deserializationResult = this.queryParamsSerializer.deserialize(this.tableToolbarService, queryParams);

        if (!deserializationResult.hasSomeFilters || this.shouldBlockUrlParamsProcessing) {
          const savedFilterScope = this.id;
          const allSavedFilters = this.savedFiltersPersistor.getSavedFilters();

          if (allSavedFilters && allSavedFilters.hasOwnProperty(savedFilterScope)) {
            try {
              Object.keys(allSavedFilters[savedFilterScope]).forEach(filterKey => {
                const savedFilter = allSavedFilters[savedFilterScope][filterKey];

                if (savedFilter.isDefault) {
                  this.tableToolbarService.clearAllFilters();

                  const searchTermControl = this.tableToolbarService.toolbarForm.get('searchTerm')!;

                  if (isSimpleQueryFilterTree(savedFilter)) {
                    for (const paramValue of (savedFilter.values as FilterItemValue[])) {
                      if (paramValue.id === FULLTEXT_SEARCH_TERM_FILTER_ID) {
                        searchTermControl.setValue(paramValue.value as string);
                      }
                      else {
                        this.tableToolbarService!.addItemValue(paramValue);
                      }
                    }
                  }
                  else {
                    searchTermControl.setValue(null);
                    this.tableToolbarService.addTreeItems(savedFilter);
                  }

                  hasFilters = true;
                  this.tableToolbarService.reloadFilters();
                }
              });
            } catch {
              throw new Error(`Saved filters loading failed.`);
            }
          }
        }
        else {
          hasFilters = true;

          if (deserializationResult.searchTerm) {
            this.searchTerm = deserializationResult.searchTerm;
          }
        }

        if (deserializationResult.page) {
          this.currentPage = {
            page: deserializationResult.page.page,
            size: deserializationResult.page.size
          };
        }

        if (deserializationResult.focusedRowKey) {
          this.focusedRowKey = deserializationResult.focusedRowKey;
        }

        if (deserializationResult.sort) {
          if (this.validateSortWithColumnData(deserializationResult.sort)) {
            this.sortChanged(deserializationResult.sort, true);
            this.setUpSort();
          }
        }

        if (hasFilters) {
          this.tableToolbarService.activeFilters$.pipe(
            filter(activeFilters => activeFilters.values.length > 0),
            take(1),
          ).subscribe(activeFilters => {
            this.filterParams = viewFilterTreeToDataFilterTree(activeFilters, this.translate.currentLang);
            this.tableToolbarService!.reloadFilters();
            this.toolbarOpened = true;
          });
        }
      });
    });
  }

  private initDataStatusObservables() {
    this.hasNoData$ = combineLatest([
      this.tableToolbarService.activeFilters$,
      this.dataSource!.items$,
    ]).pipe(
      map(([activeFilters, items]) => !items.length && !this.dataSource!.paginator?.length && isFilterTreeEmpty(activeFilters)),
    );

    this.emptyDataSubsetFound$ = combineLatest([
      this.tableToolbarService.activeFilters$,
      this.dataSource!.items$,
    ]).pipe(
      map(([activeFilters, items]) => !items.length && !this.dataSource!.paginator?.length && !isFilterTreeEmpty(activeFilters)),
    );
  }

  private validateSortWithColumnData(sort: Sort) {
    if (this.columnsData && this.columnsData.columnDefinitions) {
      const columnForSort = this.columnsData.columnDefinitions.find(column => column.id === sort.active);
      return Boolean(columnForSort && !columnForSort.disableSort);
    } else {
      return false;
    }
  }

  private setUpSort() {
    if (this.matSort && (this.config?.defaultSort || this.currentSort)) {
      this.matSort.active = 'false';

      if (this.currentSort) {
        this.matSort.sort({
          id: this.currentSort.active,
          start: this.currentSort.direction,
        } as MatSortable);

        this.sortFields = [
          {
            fieldName: this.getSortId(this.currentSort) as TColumnKey,
            descending: this.currentSort.direction === 'desc',
          }
        ];
      }
      else if (this.config.defaultSort) {
        const sortColumnDefinition = this.columnsData!.columnDefinitions.find(cd => cd.id === this.config.defaultSort!.fieldName);
        if (sortColumnDefinition && !sortColumnDefinition.disableSort) {
          this.matSort.sort({
            id: this.config.defaultSort.fieldName,
            start: this.config.defaultSort.descending ? 'desc' : 'asc',
          } as unknown as MatSortable);

          this.sortFields = [
            {
              ...this.config.defaultSort,
            }
          ];
        }
      }
    }
  }

  private applyPreselection() {
    if (this.selectedRowsService.isInitialized) {

      if (this.preselectedRows?.length) {
        this.selectedRowsService.selectMany(this.preselectedRows, true);
      }
    }
  }

  private getContentSpaceHeight(specifier: AutoPageSizeConfig): number {
    let componentTopOffset = 0;
    let componentBottomOffset = 0;

    if (this.isAutoPageSizeByOffsets(specifier)) {
      componentTopOffset = specifier.screenTopOffset;
      componentBottomOffset = specifier.screenBottomOffset;
    }

    return window.innerHeight
      - componentBottomOffset
      - componentTopOffset
      - this.getNonContentTableSpaceHeight(specifier);
  }

  private autoloadDataIfPossible() {
    if (this.tableToolbarService.filters$.value.length > 0) {
      this.reloadTable();
    } else this.loadPage();
  }

  private getTablePageSize(autoPageSizeSpecifier: AutoPageSizeConfig) {
    let pageSize: number;

    if (this.tablePageRowCount) {
      return this.tablePageRowCount;
    }
    else {
      if (this.isAutoPageSizeByManualHeight(autoPageSizeSpecifier)) {
        pageSize = this.getPageSizeByContentHeight(
          autoPageSizeSpecifier.tableHeight -
          this.getNonContentTableSpaceHeight(autoPageSizeSpecifier)
        );
      }
      else {
        pageSize = this.getPageSizeByScreenHeight(autoPageSizeSpecifier);
      }

      return Math.min(
        Math.max(pageSize, ROW_COUNT_MULTIPLE - 1),
        PAGE_SIZE_LIMIT // row count is limited due to p4m reasons
      );
    }
  }

  private getPageSizeByScreenHeight(specifier: AutoPageSizeConfig) {
    const pageSize = this.getPageSizeByContentHeight(this.getContentSpaceHeight(specifier));

    return pageSize;
  }

  private getNonContentTableSpaceHeight(specifier: AutoPageSizeConfig): number {
    // 3 * TABLE_TOOLBAR_HEIGHT because we have to count in:
    // - table toolbar (action buttons, table name, etc.),
    // - table data header - the first <tr> in <table>,
    // plus 1 px for material 16 paginator height correction
    let out = TABLE_TOOLBAR_HEIGHT + TABLE_HEADER_HEIGHT + 1;
    if (!this.dataSource?.disablePagination) {
      // - paginator at the bottom of the component
      out +=  TABLE_TOOLBAR_HEIGHT;
    }

    if (!this.isAutoPageSizeByOffsets(specifier)) {
      out += TABLE_TOOLBAR_HEIGHT; // for manual height/full-auto height inference
    }

    // If the filter toolbar is open on table initialization,
    // subtract its height from available space, too.
    if (this.config.toolbarConfig.autoOpenFilter) {
      out += TABLE_TOOLBAR_HEIGHT;
    }

    if (this.tableToolbar?.hasTabsContent) {
      out += TABLE_TOOLBAR_HEIGHT;
    }

    return out;
  }

  private getPageSizeByContentHeight(contentSpaceHeight: number) {
    const availableRowCount = contentSpaceHeight / this.config.rowHeight;

    // We want to have our page size as a multiple of a meaningful
    // number (5, 10, ... whatever). this code does exactly that.
    return (
      availableRowCount % ROW_COUNT_MULTIPLE === 0 ?
        availableRowCount : // It matches to row count multiple
        (Math.floor(availableRowCount / ROW_COUNT_MULTIPLE)
          * ROW_COUNT_MULTIPLE) + ROW_COUNT_MULTIPLE - 1 // it takes the nearest higher multiple of rows
    );
  }

  protected onRowClick(event: MouseEvent, row: TableRow, checkboxClick: boolean) {
    const allowSelection = this.columnsData!.columnDefinitions.find(c => c.id === TableReservedColumns.SELECTION);

    if (checkboxClick) event.stopPropagation();
    if (this.isRowSelectionDisabled(row) || this.isRowDisabledByDisabler(row)) return; // do nothing if this row is disabled

    // todo(lp) note: multiple de/selection with SHIFT key only works if clicked exactly in checkbox, not via generousAreaClick()
    // single selection of row works fine both from checkbox and from generous click area
    if (checkboxClick && allowSelection && event.shiftKey) { // select multiple rows at once with SHIFT + click
      if (row.id == null) return; // better do anything if row data object has no id prop

      let rowsToSelect: any[] = [];
      const dataThisPage = this.dataSource!.data; // yes, contains data in actual displayed order after sorting, paging etc.
      // it's important to filter invalid indices and have the array sorted for further processing
      combineLatest([
        this.selectedRowsService.selectedRows$,
      ]).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(([selected]) => {
        this.isGlobalSelected = false;
        this.selectedRows = [...selected];
      });
      const selectedIndices = this.selectedRows
        .map(s => dataThisPage.findIndex(d => areTableRowsEqual(this.rowKeyFn, s, d)))
        .filter(f => f !== -1).sort();
      if (selectedIndices.length === 0) return; // selectedIndices can be empty if there are no selected rows, also if there are selected rows on different pages, we don't care

      const clickedIndex = dataThisPage.findIndex(d => areTableRowsEqual(this.rowKeyFn, row, d));
      const minSelected = Math.min(...selectedIndices);
      const maxSelected = Math.max(...selectedIndices);

      // if also CTRL key, unselect multiple rows with CTRL + SHIFT + click
      if (event.ctrlKey) {
        if (clickedIndex <= maxSelected) {
          rowsToSelect = dataThisPage.slice(minSelected, checkboxClick ? clickedIndex : clickedIndex + 1);
          if (rowsToSelect.length) this.selectedRowsService.deselectMany(rowsToSelect);
        }
        // else select more
      } else {
        // select rows above first selected
        if (clickedIndex < minSelected) {
          rowsToSelect = dataThisPage.slice(checkboxClick ? clickedIndex + 1 : clickedIndex, minSelected + 1);
        }
        // select rows below last selected
        else if (clickedIndex > maxSelected) {
          rowsToSelect = dataThisPage.slice(maxSelected, checkboxClick ? clickedIndex : clickedIndex + 1);
        }
        // select rows between first and last selected
        else if (clickedIndex > minSelected && clickedIndex < maxSelected) {
          const missingInRange = Array.from(Array(maxSelected - minSelected), (v, i) => i + minSelected).filter(i => !selectedIndices.includes(i));
          const relevant = missingInRange.filter(missing => missing <= clickedIndex);
          rowsToSelect = dataThisPage.filter((row, index) => relevant.indexOf(index) !== -1);
        }
        if (rowsToSelect.length) this.selectedRowsService.selectMany(rowsToSelect);
      }
    }
    else if (!checkboxClick) {
      this.selectedRowsService.toggleActiveRow(row);
      this.rowClicked.emit(row);
    }
  }

  private loadPage() {
    this.dataSource!.items$.pipe(skip(1), take(1)).subscribe(items => {
      // when page is loaded deselect all and apply preselection
      this.applyPreselection();
      const paginator = this.dataSource!.paginator!;
      if (paginator) {
        const maxPages = Math.ceil(paginator.length / paginator.pageSize);
        if (paginator.length > 0 && paginator.pageIndex >= maxPages) {
          this.pageNumberChanged({pageSize: paginator.pageSize, pageIndex: maxPages - 1, length: paginator.length});
        }
      }
    });

    if (this.dataSource!.paginator) {
      if (this.currentPage) {
        this.dataSource!.paginator.pageSize = this.currentPage.size;
        this.tablePaginator.setPage(this.currentPage.page);
      }
      const currentSearchParams = this.getCurrentSearchParams();
      this.pageLoadStarted.emit(cloneDeep(currentSearchParams));
      this.dataSource!.loadPage(currentSearchParams);
    }
  }

  protected searchTermChanged(searchTerm: string) {
    this.searchTerm = searchTerm || ' '; // this how elastic API accepts empty searchTerm query

    this.selectedRowsService.deselectAll();
    this.dataSource!.paginator!.firstPage();
    this.loadPage();

    this.searchTermChange.emit(this.searchTerm);
    this.patchHistoryBit();
  }

  protected openContextMenu() {
    this.showContextMenu$.next(true);
  }

  protected closeContextMenu() {
    this.showContextMenu$.next(false);
  }

  private patchHistoryBit() {
    if (this.shouldBlockUrlParamsProcessing) return;

    this.queryParamsSerializer.serialize(
      EsslTableQueryParametersSerializationMode.ALL_PARAMS,
      this.searchTerm,
      this.activeFilters,
      this.currentSort,
      this.currentPage,
      this.focusedRowKey,
    );
  }

  protected isRowSelectionDisabled(row: any) {
    return this.rowSelectionDisabler ? this.rowSelectionDisabler(row) : false;
  }

  protected isRowDisabledByDisabler(row: any) {
    return this.rowDisabler ? this.rowDisabler(row) : false;
  }

  protected isRowFocused(row: any) {
    if (this.focusedRowKey) {
      return areTableRowKeysEqual(this.rowKeyFn(row), this.focusedRowKey);
    }
    else {
      return false;
    }
  }

  protected tableCheckboxClicked(checked: PrimitiveControlValueType, row: TableRow) {
    this.selectedRowsService.rowSelectionChanged(checked!, row);
  }

  /**
   * @param forcePageChange forces pageSize and pageNumber serialization even if pageNumber does not change (use when changing pageSize)
   */
  protected pageNumberChanged(event: PageEvent, forcePageChange = false) {
    if (!forcePageChange && this.currentPage?.page === event.pageIndex) {
      return;
    }

    this.currentPage = {
      page: event.pageIndex,
      size: event.pageSize
    };

    this.pageChanged.next(event);

    if (this.shouldBlockUrlParamsProcessing) return;

    this.queryParamsSerializer.serialize(
      EsslTableQueryParametersSerializationMode.PAGE_PARAM_ONLY,
      this.searchTerm,
      this.activeFilters,
      this.currentSort,
      this.currentPage,
      this.focusedRowKey,
    );
  }

  protected rowCountSettingsChanged(newRowCount: number) {
    this.tablePropertiesPersistor.setTablePageRowCount(newRowCount);
    this.tablePageRowCount = newRowCount;

    const paginator = this.dataSource!.paginator!;

    paginator.pageSize = this.getTablePageSize(this.config.autoPageSizeConfig);
    paginator.pageIndex = 0;
    this.pageNumberChanged(
      {
        pageSize: paginator.pageSize,
        pageIndex: paginator.pageIndex,
        length: paginator.length
      },
      true
    );

    this.reloadTable();
  }

  protected getCheckboxTooltip(row: any) {
    if (this.selectedRowsService.isSelectionDisabled(row)) {
      return this.disabledRowTooltip ?? 'Nelze vybrat';
    }
    else {
      return '';
    }
  }

  protected isEllipsisApplied(column: ColumnDefinition<string>) {
    return !this.customTableCells[column.id] || (this.customTableCells[column.id] && this.customTableCells[column.id].withEllipsis);
  }

  protected sortChanged(sort: Nullable<Sort>, ignoreLocalStorage = false) {
    this.currentSort = sort;
    if (!ignoreLocalStorage) this.saveTableSortToLocalStorage();
    this.sorted.next(sort);

    if (this.shouldBlockUrlParamsProcessing) return;

    this.queryParamsSerializer.serialize(
      EsslTableQueryParametersSerializationMode.SORT_PARAM_ONLY,
      this.searchTerm,
      this.activeFilters,
      this.currentSort,
      this.currentPage,
      this.focusedRowKey
    );
  }

  protected isColumnReserved(column: ColumnDefinition<string>) {
    return RESERVED_COLUMNS_VALUES.includes(column.id);
  }

  private saveTableSortToLocalStorage() {
    if (this.currentSort && !this.config.disableLocalStorageSortPersistence) {
      const propertiesFromLocalStorage = this.tablePropertiesPersistor.getTableProperties(this.id);
      const sortPropertiesToSave = {
        sortColumnId: this.currentSort.active,
        sortDirection: this.currentSort.direction,
      };

      let valueToSave: PersistedUserTableProperties;

      if (propertiesFromLocalStorage) {
        valueToSave = {
          ...propertiesFromLocalStorage,
          sort: sortPropertiesToSave,
        };
      }
      else {
        valueToSave = {
          tableSchemaVersionHash: this.tableSchemaVersionHash,
          sort: sortPropertiesToSave,
          columns: null,
        };
      }

      this.tablePropertiesPersistor.saveTableProperties(this.id, valueToSave);
    }
  }

  private initSelectedRowsService() {
    this.selectedRowsService.init(this.dataSource!, this.rowKeyFn, this.rowSelectionDisablingFnChanges$);

    this.itemsSub.unsubscribe();
    this.filterOrSortChangeSub.unsubscribe();
    this.rowSelectionSub.unsubscribe();
    this.activeRowSub.unsubscribe();

    this.itemsSub = this.dataSource!.items$.pipe(
      withLatestFrom(this.selectedRowsService.selectedRows$),
      takeUntilDestroyed(this.destroyRef),
      skip(1),
    ).subscribe(([items, selected]) => {
      if (items && !this.config.allowMultiPageSelection) {
        this.selectedRowsService.deselectMany(selected);
        this.selectedRowsService.selectMany(items.filter(d => selected.some(s => areTableRowsEqual(this.rowKeyFn, s, d))), true);
      }
    });

    this.filterOrSortChangeSub = merge(
      this.filterChanged$,
      this.sorted.pipe(distinctUntilChanged(isEqual)),
    ).pipe(
      withLatestFrom(this.selectedRowsService.selectedRows$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([_, selected]) => {
      if (this.config.allowMultiPageSelection) {
        this.selectedRowsService.deselectMany(selected);
      }
    });

    this.rowSelectionSub = this.selectedRowsService.selectedRows$.pipe(
      distinctUntilChanged(isEqual),
      takeUntilDestroyed(this.destroyRef),
      skip(1),
    ).subscribe(selected => {
      this.isGlobalSelected = false;
      this.selectedRowsChanged.emit([...selected]);
    });

    this.activeRowSub = this.selectedRowsService.activeRow$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(this.activeRowChanged);
  }

  private isAutoPageSizeByOffsets(specifier: AutoPageSizeConfig): specifier is AutoPageSizeByOffsets {
    const typedSpecifier = specifier as AutoPageSizeByOffsets;

    return (typedSpecifier.screenTopOffset !== undefined &&
      typedSpecifier.screenBottomOffset !== undefined);
  }

  private isAutoPageSizeByManualHeight(specifier: AutoPageSizeConfig): specifier is AutoPageSizeByManualHeight {
    return (specifier as AutoPageSizeByManualHeight).tableHeight !== undefined;
  }

  private loadAsyncColumnLists(columnIdsToLoad?: Nullable<string[]>) {
    this.columnsData!.columnDefinitions
      .filter(column => columnIdsToLoad ? columnIdsToLoad.includes(column.id) : true)
      .forEach(column => {
        if (column.id === TableReservedColumns.SELECTION) column.fixedWidth = this.SELECTION_COLUMN_WIDTH;

        if ((column as CodebookFilterDefinition | EnumFilterDefinition).list$) {
          this.columnsData!.setColumnLoading(column.id, true);
          this.asyncListObservables[column.id] = (column as CodebookFilterDefinition | EnumFilterDefinition).list$!;
          (column as CodebookFilterDefinition).list$!.pipe(
            takeUntilDestroyed(this.destroyRef),
            finalize(() => {
              this.columnsData!.setColumnLoading(column.id, false);
            }),
          ).subscribe(list => {
            this.columnsData!.setColumnLoading(column.id, false);
            this.columnsData!.setColumnList(column.id, list);
          });
        }
      });
  }

  private initializeTableToolbar() {
    this.tableToolbarService.init(this.config.defaultFilterColumns, this.columnsData!);
  }

  private connectDatasourceStateToPaginator() {
    this.dataSourceConnectSubscription?.unsubscribe();

    this.dataSourceConnectSubscription = this.dataSource!.connect().pipe(
      filter(data => Boolean(data.length)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_data => {
      setTimeout(() => {
        this.tablePaginator.setAvailablePagesOptions();
      });
    });

    this.dataSource!.connect().pipe(
      filter(data => Boolean(data.length)),
      take(1)
    ).subscribe(_ => {});
  }

  private getCurrentSearchParams(): SearchParams {
    if (isSimpleQueryFilterTree(this.filterParams)) {
      return {
        page: this.currentPage ? this.currentPage.page : this.dataSource!.paginator!.pageIndex,
        size: this.currentPage ? this.currentPage.size : this.dataSource!.paginator!.pageSize,
        sort: this.sortFields,
        filter: [...this.filterParams.values] as FilterParam[],
        fulltextSearchTerm: this.searchTerm,
      };
    }
    else {
      return {
        page: this.currentPage ? this.currentPage.page : this.dataSource!.paginator!.pageIndex,
        size: this.currentPage ? this.currentPage.size : this.dataSource!.paginator!.pageSize,
        sort: this.sortFields,
        filter: [],
        fulltextSearchTerm: this.searchTerm,
        complexFilter: this.filterParams,
      };
    }
  }

  // Semi-deep clone - shallow clones column def array and column def items
  //  but not ColumnDefinition#list which are usually very big and thus
  //  significantly delay affected operations which use object copying.
  private cloneColumnDefinitions(columnDefinitions: ColumnDefinition<TColumnKey>[]): ColumnDefinition<TColumnKey>[] {
    return columnDefinitions.map(cd => ({...cd}));
  }

  /**
   * Extension points for TreeTable, it is here because we want to share templates and commonalities of icz-table
   *  with icz-tree-table and icz-table template thus contains a table expander element which has references to the symbols below.
   * @param row
   */
  protected isTreeTable = false;

  protected isExpanded(row: TableRow) {
    return false;
  }

  protected getRowLevelIndentValue(row: TableRow) {
    return 0;
  }

  protected isNodeExpanderVisible(row: TableRow) {
    return false;
  }

  protected toggleExpansionState(event: Event, row: TableRow) {}

}
