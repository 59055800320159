import {MatTableDataSource} from '@angular/material/table';
import {clone, cloneDeep, isEqual, sortBy} from 'lodash';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, filter, map, retry, share, switchMap, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SearchParams} from './table.utils';
import {Page} from './page';

/**
 * @internal
 */
export interface LoadPage {
  lastSearchParams: SearchParams;
  currentSearchParams: SearchParams;
  deselectAll: boolean;
}

/**
 * @internal
 */
export interface SearchRequestWithResult<T> {
  request: LoadPage;
  result: Page<T>;
}

/**
 * @internal
 */
export const defaultPageSize = 20;

/**
 * Constructs default search params object
 */
export const getDefaultSearchParams = (): SearchParams => ({
  page: 0,
  size: defaultPageSize,
  sort: [],
  filter: [],
});

/**
 * Merges two searchParams into combined searchParams which. Properties of params2 will override the properties of params1.
 * params2 might be partial; because params1 are consistent, it will produce consistent result.
 */
export function mergeSearchParams(params1: SearchParams, params2: Partial<SearchParams>): SearchParams {
  return {
    ...params1,
    ...params2,
    sort: [
      ...(params1.sort || []),
      ...(params2.sort || []),
    ],
    filter: [
      ...(params1.filter || []),
      ...(params2.filter || []),
    ],
  };
}

/**
 * Fetches data from data producer and provides them to the consumer.
 * Data can be both synchronous - in memory array and asynchronous data - data from an endpoint.
 */
export class IczTableDataSource<T> extends MatTableDataSource<T> {

  /**
   * Used for passing in additional hidden search parameters which are not originated from table toolbar filters.
   * It is partial because these parameters might not need to set page size and page number, for example.
   */
  additionalSearchParams: Nullable<Partial<SearchParams>>;
  /**
   * Hides paginator in a consuming table.
   */
  disablePagination = false;

  /**
   * An observable of loading states of the data in the datasource.
   * true - is loading; false = isn't loading.
   */
  loading$!: Observable<boolean>;
  /**
   * An observable of errors which occurred during data loading.
   * null - no error.
   */
  error$!: Observable<Nullable<HttpErrorResponse>>;
  /**
   * Emits unselect all command towards a table which consumes this datasource.
   */
  unselectAll$!: Observable<void>;
  /**
   * An observable of data which got requested by the datasource.
   */
  loadPageResult$!: Observable<Page<T>>;
  /**
   * An observable of data records which are located on current page which got requested by the datasource.
   */
  items$!: Observable<T[]>;

  private loadPageRequestWithResult$!: Observable<SearchRequestWithResult<T>>;

  protected _unselectAll$!: Subject<void>;
  protected _items$!: BehaviorSubject<T[]>;
  protected _error$!: BehaviorSubject<Nullable<HttpErrorResponse>>;
  protected _loading$!: BehaviorSubject<boolean>;
  protected _loadPage$!: Subject<Nullable<LoadPage>>;
  protected lastSearchParams!: SearchParams;

  constructor(
    protected search$: (searchParams: SearchParams) => Observable<Page<T>>,
  ) {
    super();
    this.initialize();
  }

  /**
   * Overrides _filterData() of MatTableDataSource to not modify paginator.length
   * @internal
   */
  override _filterData(data: T[]) {
    this.filteredData =
      !this.filter ? data : data.filter((obj => this.filterPredicate(obj, this.filter)));
    return this.filteredData;
  }

  /**
   * Instructs the data producer to supply data again, with the same filtering,
   * sorting and pagination criteria as the last data request.
   * @param deselectAll
   */
  reload(deselectAll = false) {
    if (!this.lastSearchParams) this.lastSearchParams = getDefaultSearchParams();
    this.loadPage(this.lastSearchParams, deselectAll);
  }

  /**
   * Instructs the data source to request data from data producer
   * @param searchParams filtering/sorting parameters to be passed to data producer
   * @param deselectAll instructs consumer table to deselect rows in its page view
   */
  loadPage(searchParams: SearchParams, deselectAll = false) {
    this._loadPage$.next({
      lastSearchParams: searchParams,
      currentSearchParams: this.enhanceWithWithAdditionalSearchParams(searchParams),
      deselectAll
    });
  }

  /**
   * @internal
   */
  override connect(): BehaviorSubject<T[]> {
    return this._items$;
  }

  /**
   * @internal
   */
  override disconnect(): void {
    this._items$.complete();
    this._loading$.complete();
    this._loadPage$.complete();

    // after disconnecting, the datasource should
    // be reusable in the same context
    this.initialize();
  }

  protected setData(data: T[]) {
    this.data = data;
    this._items$.next(data);
  }

  protected initialize() {
    this._unselectAll$ = new Subject<void>();
    this._items$ = new BehaviorSubject<T[]>([]);
    this._loading$ = new BehaviorSubject<boolean>(false); // false because we might not need to load data right away after page load
    this._error$ = new BehaviorSubject<Nullable<HttpErrorResponse>>(null);
    this._loadPage$ = new Subject<Nullable<LoadPage>>();
    this.unselectAll$ = this._unselectAll$.asObservable();
    this.items$ = this._items$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.loadPageRequestWithResult$ = this._loadPage$.pipe(
      filter(loadPage => Boolean(loadPage && loadPage.currentSearchParams)),
      tap(_ => {
        this._loading$.next(true);
      }),
      debounceTime(100),
      tap(loadPage => {
        if (loadPage!.deselectAll) {
          this._unselectAll$.next();
        }
        if (this.hasSortOrFilterChanged(loadPage!.currentSearchParams)) {
          loadPage!.currentSearchParams.page = 0;
          this.paginator?.firstPage();
        }
      }),
      switchMap(loadPage => this.search$(clone(loadPage!.currentSearchParams)).pipe(
        map(page => ({
          request: loadPage!,
          result: page,
        })),
      )),
      tap({
        error: e => {
          if (e instanceof HttpErrorResponse) {
            this.setData([]);
            this._error$.next(e);
            this._loading$.next(false);
          }else {
            // eslint-disable-next-line no-console -- important for catching hard-to-debug issues
            console.error(e);
          }
        },
      }),
      retry(),
      filter(page => Boolean(page)),
      share(),
    ) as Observable<SearchRequestWithResult<T>>;
    this.loadPageResult$ = this.loadPageRequestWithResult$.pipe(
      map(searchRequestWithResult => searchRequestWithResult.result)
    );

    this.loadPageRequestWithResult$.subscribe({
      next: searchRequestWithResult => {
        this.handleLoadDataResult(searchRequestWithResult);
        this.lastSearchParams = searchRequestWithResult.request.lastSearchParams;
      },
      error: error => {
        this.setData([]);
        this._error$.next(error);
        this._loading$.next(false);
        if (this.paginator) this.paginator.length = 0;
        throw error;
      }
    });
  }

  protected enhanceWithWithAdditionalSearchParams(searchParams: SearchParams) {
    if (this.additionalSearchParams) {
      return mergeSearchParams(
        searchParams,
        this.additionalSearchParams
      );
    }
    else {
      return cloneDeep(searchParams);
    }
  }

  protected hasSortOrFilterChanged(searchParams: SearchParams) {
    if (searchParams && this.lastSearchParams) {
      // searchParams come in already merged but this.lastSearchParams are unmerged;
      //  this construct constructs lastSearchParams such that they are mutually
      //  comparable without false positives.
      const finalLastSearchParams = mergeSearchParams(
        this.lastSearchParams,
        this.additionalSearchParams ?? {}
      );

      return (
        !isEqual(sortBy(searchParams.sort), sortBy(finalLastSearchParams.sort)) ||
        !isEqual(sortBy(searchParams.filter), sortBy(finalLastSearchParams.filter))
      );
    }
    else {
      return false;
    }
  }

  protected handleLoadDataResult(searchRequestWithResult: SearchRequestWithResult<T>) {
    // setting this.paginator.length must be before setData() which fires this._items$.next
    if (this.paginator) this.paginator.length = searchRequestWithResult.result.totalElements ?? 0;
    this.setData(searchRequestWithResult.result.content ?? []);
    this._error$.next(null);
    this._loading$.next(false);
  }

}
