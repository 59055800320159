import {getEsslComponentTemplate, NotificationModelMappers} from './abstract-notification-message-content';
import {
  InternalNotificationBulkComponentActionMessageCode,
  InternalNotificationBulkDocumentActionMessageCode,
  InternalNotificationBulkFileActionMessageCode,
  InternalNotificationConsignmentMessageCode,
  InternalNotificationMessageCode
} from '../../../core/services/notifications/internal-notification.enum';
import {
  EsslComponentToastType,
  SConversionErrorTemplateData,
  SDigitalComponentTemplateData
} from '../../../core/services/notifications/essl-component-toast.service';
import {
  CirculationToastType,
  SCirculationBulkTaskAssignedInitiatorData,
  SCirculationBulkTaskAssignedInitiatorErrorData,
  SCirculationBulkTaskErrorByTargetData,
  SCirculationBulkTaskSuccessByTargetData,
  SCirculationHandoverTaskAssignedInitiatorData,
  SCirculationHandoverTaskPassedInitiatorData,
  SCirculationHandoverTaskRejectedInitiatorData,
  SCirculationHandoverTaskRevokedTargetParticipantData,
  SCirculationHandoverTaskSuccessInitiatorData,
  SCirculationHandoverToOwnHandsTaskReturnedInitiatorData,
  SCirculationTaskAssignedInitiatorData,
  SCirculationTaskPassedInitiatorData,
  SCirculationTaskRejectedInitiatorData,
  SCirculationTaskRevokedInitiatorData,
  SCirculationTaskRevokedTargetParticipantData,
  SCirculationTaskSuccessInitiatorData
} from '../../../core/services/notifications/circulation-toast.service';
import {
  RenditionToastType,
  SFilingRegisterRenditionFinishedTemplateData,
  SFilingRegisterRenditionScheduleScheduledTemplateData
} from '../../../core/services/notifications/rendition-toast.service';
import {
  DocumentToastType,
  SDocumentTemplateData,
  SDocumentTemplateDataError
} from '../../../core/services/notifications/document-toast.service';
import {
  EsslComponentCirculationToastType,
  SBulkCirculatedEsslComponentData,
  SCirculatedEsslComponentData,
  SCirculatedEsslComponentErrorData
} from '../../../core/services/notifications/essl-component-circulation-toast.service';
import {
  FileCirculationToastType,
  SFileCirculationBulkTaskSuccessByTargetData,
  SFileCirculationTaskErrorData
} from '../../../core/services/notifications/file-circulation-toast.service';
import {
  SStorageUnitEntityTemplateData,
  SStorageUnitFillerTemplateData,
  SStorageUnitTemplateData,
  StorageUnitToastType
} from '../../../core/services/notifications/storage-unit-toast.service';
import {
  EpdzErrorToastType,
  SEpdzErrorErrorTemplateData,
  SEpdzErrorTemplateData
} from '../../../core/services/notifications/epdz-error-toast.service';
import {FileToastType, SFileTemplateData} from '../../../core/services/notifications/file-toast.service';
import {
  RegistryOfficeToastType,
  SRegistryOfficeTransferErrorTemplateData,
  SRegistryOfficeTransferIncidentErrorTemplateData,
  SRegistryOfficeTransferIncidentTemplateData,
  SRegistryOfficeTransferTemplateData
} from '../../../core/services/notifications/registry-office-toast.service';

/**
 * Because we want notification templates to be shared with toast templates and
 * notification parameters are sent as plain array without keys, we need mappers.
 */
export const MESSAGE_BODY_MAPPERS: NotificationModelMappers<InternalNotificationMessageCode |
  InternalNotificationConsignmentMessageCode |
  InternalNotificationBulkDocumentActionMessageCode |
  InternalNotificationBulkComponentActionMessageCode |
  InternalNotificationBulkFileActionMessageCode
> = {
  // null means "no body".
  [InternalNotificationMessageCode.CONVERSION_SUCCESS]: null,
  [InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_SUCCESS]: null,
  [InternalNotificationMessageCode.CONVERSION_ERROR]: {
    template: EsslComponentToastType.CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_ERROR]: {
    template: EsslComponentToastType.CONVERSION_WITH_CLAUSE_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationMessageCode.FLOW_TASK_ASSIGNED_INITIATOR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR,
    schema: SCirculationTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_TASK_SUCCESS_INITIATOR]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_INITIATOR,
    schema: SCirculationTaskSuccessInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_TASK_REJECTED_INITIATOR]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_INITIATOR,
    schema: SCirculationTaskRejectedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_TASK_REVOKED_INITIATOR]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_INITIATOR,
    schema: SCirculationTaskRevokedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_TASK_PASSED_INITIATOR]: {
    template: CirculationToastType.FLOW_TASK_PASSED_INITIATOR,
    schema: SCirculationTaskPassedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SCirculationHandoverTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR,
    schema: SCirculationHandoverTaskSuccessInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TASK_REJECTED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_REJECTED_INITIATOR,
    schema: SCirculationHandoverTaskRejectedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT,
    schema: SCirculationHandoverTaskRevokedTargetParticipantData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR,
    schema: SCirculationHandoverToOwnHandsTaskReturnedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR,
    schema: SCirculationHandoverTaskRejectedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR,
    schema: SCirculationHandoverTaskRejectedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR,
    schema: SCirculationHandoverTaskSuccessInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR,
    schema: SCirculationHandoverTaskPassedInitiatorData,
  },
  [InternalNotificationMessageCode.FLOW_HANDOVER_TASK_PASSED_INITIATOR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_PASSED_INITIATOR,
    schema: SCirculationHandoverTaskPassedInitiatorData,
  },
  [InternalNotificationMessageCode.FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED]: {
    template: RenditionToastType.FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED,
    schema: SFilingRegisterRenditionScheduleScheduledTemplateData,
  },
  [InternalNotificationMessageCode.FILING_REGISTER_RENDITION_FINISHED]: {
    template: RenditionToastType.FILING_REGISTER_RENDITION_FINISHED,
    schema: SFilingRegisterRenditionFinishedTemplateData,
  },
  [InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_APPROVED_INITIATOR]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_REVOKED_TARGET_PARTICIPANT]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_DISAPPROVED_INITIATOR]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_REJECTED_INITIATOR]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_PASSED_INITIATOR]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_SIGNED_INITIATOR]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR),
    schema: SCirculatedEsslComponentData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_REVOKED_TARGET_PARTICIPANT]: null,
  [InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_REJECTED_INITIATOR]: null,
  [InternalNotificationMessageCode.OFFICE_DESK_POST_SUCCESS]: null,
  [InternalNotificationMessageCode.OFFICE_DESK_POST_ERROR]: null,
  [InternalNotificationMessageCode.OFFICE_DESK_UNPOST_SUCCESS]: null,
  [InternalNotificationMessageCode.OFFICE_DESK_UNPOST_ERROR]: null,
  [InternalNotificationMessageCode.EXECUTION_DATE_CLAUSE_CREATE_SUCCESS]: null,
  [InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_SETTLED,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_ERROR]: {
    template: DocumentToastType.DOCUMENT_SETTLE_ERROR,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS]: {
    template: DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_WITHDRAW_SETTLE_DOCUMENT_ERROR]: {
    template: DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_ERROR,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.FILE_SETTLEMENT_SUCCESS]: {
    template: FileToastType.FILE_SETTLED,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.FILE_SETTLEMENT_ERROR]: {
    template: FileToastType.FILE_SETTLE_ERROR,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_WITHDRAW_SETTLE_FILE_SUCCESS]: {
    template: FileToastType.FILE_WITHDRAW_SETTLEMENT,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_WITHDRAW_SETTLE_FILE_ERROR]: {
    template: FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_SUCCESS]: {
    template: FileToastType.FILE_CLOSED,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_ERROR]: {
    template: FileToastType.FILE_CLOSE_ERROR,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_REOPEN_FILE_SUCCESS]: {
    template: FileToastType.FILE_REOPENED_SUCCESS,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkFileActionMessageCode.BULK_REOPEN_FILE_ERROR]: {
    template: FileToastType.FILE_REOPENED_ERROR,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_INSERTED_TO_FILE,
    schema: SFileTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_INSERTED_TO_FILE_ERROR,
    schema: SDocumentTemplateDataError,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_REMOVED_FROM_FILE,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_REMOVED_FROM_FILE_ERROR,
    schema: SDocumentTemplateDataError,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE_ERROR,
    schema: SDocumentTemplateDataError,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_REGISTERED,
    schema: SDocumentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_ERROR]: {
    template: DocumentToastType.DOCUMENT_REGISTERED_ERROR,
    schema: SDocumentTemplateDataError,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_ERROR]: {
    template: EsslComponentToastType.CONVERSION_TO_OUTPUT_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_ERROR]: {
    template: EsslComponentToastType.CONVERSION_TO_OUTPUT_WITH_CLAUSE_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_LOCKED,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_LOCKED_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_UNLOCKED,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_UNLOCKED_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_DELETION_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_ERROR]: {
    template: EsslComponentToastType.COMPONENT_DELETION_ERROR,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_HANDOVER_FOR_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR),
    schema: SBulkCirculatedEsslComponentData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_HAND_FOR_APPROVAL_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR),
    schema: SBulkCirculatedEsslComponentData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_ANALOG_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT),
    schema: SCirculatedEsslComponentData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_DIGITAL_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT),
    schema: SCirculatedEsslComponentData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SCirculationBulkTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.BULK_FILE_HANDOVER_SUCCESS]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SFileCirculationBulkTaskSuccessByTargetData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_HANDOVER_TAKEOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.BULK_HANDOVER_FILE_TAKEOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_FILE_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_APPROVAL_DOCUMENT_INITIATOR,
    schema: SCirculationBulkTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_STATEMENT_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_STATEMENT_INITIATOR,
    schema: SCirculationBulkTaskAssignedInitiatorData
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_ACKNOWLEDGMENT_INITIATOR,
    schema: SCirculationBulkTaskAssignedInitiatorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT,
    schema: SCirculatedEsslComponentData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT,
    schema: SCirculatedEsslComponentData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_FILE_TAKEOVER_AND_PASS_SUCCESS]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SFileCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_TASK_REJECT_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_TASK_REVOKE_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_TASK_PASS_TO_NEXT_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskSuccessByTargetData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_HANDOVER_FOR_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_HAND_FOR_APPROVAL_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_ANALOG_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_DIGITAL_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_SIGNING_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_ERROR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculationBulkTaskAssignedInitiatorErrorData,
  },
  [InternalNotificationMessageCode.BULK_FILE_HANDOVER_ERROR]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SFileCirculationTaskErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_TAKEOVER_ERROR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_TARGET_PARTICIPANT_ERROR,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_STATEMENET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculationBulkTaskAssignedInitiatorErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculationBulkTaskAssignedInitiatorErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SCirculationBulkTaskAssignedInitiatorErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_APPROVE_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_APPROVE_BY_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_BY_TARGET_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_DISAPPROVAL_BY_TARGET_ERROR,
    schema: SCirculatedEsslComponentErrorData,
  },
  [InternalNotificationMessageCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_FILE_TAKEOVER_AND_PASS_ERROR]: {
    template: FileCirculationToastType.FILE_TASK_CIRCULATION_BY_TARGET_ERROR,
    schema: SFileCirculationTaskErrorData,
  },
  [InternalNotificationMessageCode.BULK_TASK_REJECT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT_ERROR,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_TASK_REVOKE_ERROR]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT_ERROR,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_TASK_PASS_TO_NEXT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT_ERROR,
    schema: SCirculationBulkTaskErrorByTargetData,
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_LOCK_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_BLOCK_SUCCESS,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_LOCK_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_BLOCK_FAILED,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_UNLOCK_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_UNBLOCK_SUCCESS,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_UNLOCK_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_UNBLOCK_FAILED,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_DELETE_SUCCESS,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_DELETE_FAILED,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false
  },
  [InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_INSERT_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
    preserveBodyMode: false,
    },
  [InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_INSERT_ERROR,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_WITHDRAW_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_WITHDRAW_ERROR,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_MOVE_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_MOVE_ERROR,
    schema: SStorageUnitTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_EPDZ_ERROR_PROCESSING_SUCCESS]: {
    template: EpdzErrorToastType.EPDZ_ERROR_PROCESSING_SUCCESS,
    schema: SEpdzErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_EPDZ_ERROR_PROCESSING_ERROR]: {
    template: EpdzErrorToastType.EPDZ_ERROR_PROCESSING_ERROR,
    schema: SEpdzErrorErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.EPDZ_ERROR_GENERAL]: null,
  [InternalNotificationMessageCode.EPDZ_ERROR_NO_CONTAINER]: null,
  [InternalNotificationMessageCode.EPDZ_ERROR_CONTAINER_CORRUPT]: null,
  [InternalNotificationMessageCode.FLOW_TASK_REVOKED_TARGET_PARTICIPANT]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT,
    schema: SCirculationTaskRevokedTargetParticipantData,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS]: {
    template: RegistryOfficeToastType.RO_INCIDENT_RESOLVE_SUCCESS,
    schema: SRegistryOfficeTransferIncidentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_ERROR]: {
    template: RegistryOfficeToastType.RO_INCIDENT_RESOLVE_ERROR,
    schema: SRegistryOfficeTransferIncidentErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_CANNOT_CONTINUE_WITH_NOT_SYNCHRONIZED_CLASSIFICATION_SCHEME]: null,
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_CANCEL_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CANCEL_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_CANCEL_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CANCEL_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_RETRANSFER_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_RETRANSFER_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_CREATED_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CREATED_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_CREATED_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CREATED_ERROR,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_TAKEN_OVER_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_REJECT_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_REJECTED_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.BULK_RO_TRANSFER_REJECT_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_REJECTED_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_SUCCESS]: null,
  [InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_ERROR]: null,
  [InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_SUCCESS]: null,
  [InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_ERROR]: null,
  [InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_COMPLETED]: null,
  [InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_CANCELED]: null,
  [InternalNotificationMessageCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_ERROR]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR,
    schema: SConversionErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_ERROR]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.FORM_FILLER_CLAUSE_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS,
    schema: SDigitalComponentTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR,
    schema: SConversionErrorTemplateData,
    preserveBodyMode: false,
  },
  [InternalNotificationMessageCode.STORAGE_UNIT_FILLER_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_FILLER_SUCCESS,
    schema: SStorageUnitFillerTemplateData,
  },
};
