<div
  class="row items-center justify-between toolbar p-16"
  cdkDrag cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container"
>
  @if (isNew) {
    <span>{{ 'Naplánování automatického plnění ukládacích jednotek' | translate }}</span>
  } @else {
    <div class="row">
      <icz-tag [label]="('en.storageUnitFillerState.' + rawValue.storageUnitFillerState!) | translate"
               class="state-tag"
               [color]="stateTagColor">
      </icz-tag>
      <span>{{ 'Automatické plnění ukládacích jednotek' | translate }}</span><span>{{ rawValue.id }}</span>
    </div>
  }

  <icz-button svgIcon="close" (onAction)="cancel()" background="transparent" disableRipple size="small"></icz-button>
</div>

<icz-tabs class="grow tabs" [tabs]="tabs" (tabClick)="tabChanged($event)" [activeTab]="currentActiveTab">
  <ng-template [iczTab]="StorageUnitFillerTabs.SETTINGS">
    <form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async" class="m-24">
      <div class="row">
        <div class="col grow">
          <icz-section label="Naplánování dávky">
            <icz-radio-group>
              <div class="col gap-8">
                <icz-label label="Chcete spustit zkušební nebo finální uložení?"></icz-label>
                <icz-radio-button formControlName="finalRun" label="Testovací spuštění" [value]="false"></icz-radio-button>
                <icz-radio-button formControlName="finalRun" label="Finální spuštění" [value]="true"></icz-radio-button>
              </div>
            </icz-radio-group>
            <icz-readonly-field
              [value]="rawValue.scheduledRunAt | localizedDatetime"
              label="Datum příštího běhu">
            </icz-readonly-field>
            <icz-readonly-field
              [value]="rawValue.lastRunAt | localizedDatetime"
              label="Datum posledního běhu">
            </icz-readonly-field>
          </icz-section>
          <icz-form-autocomplete
            formControlName="registryOfficeId"
            label="Spisovna"
            [options]="registryOfficeOptions"
          ></icz-form-autocomplete>
          @if (!this.isPredefinedRegistryOfficeAvailableForMe) {
            <icz-alert
              severity="error" class="mb-16 mt-4"
              heading="Nemáte přístup k přednastavené spisovně. Kontaktujte administrátora."
            ></icz-alert>
          }
          @if (!isNew) {
            <div class="row">
              <icz-icon suffix size="small" svgIcon="info"></icz-icon>
              <span>{{ toInsertEntitiesLabel }}</span>
            </div>
          }

          <span class="mt-4">{{ 'Kritéria výběru objektů ze seznamu ukončených:' | translate }}</span>
          @if (humanReadableFilter) {
            <span class="icz-body-1 mt-4" [innerHTML]="humanReadableFilter"></span>
          }
          @if (isNew && modalData.isUnitView) {
            <div class="row mt-4">
              <span>{{ 'Vybraný útvar:' | translate }}</span>
              @if (orgUnitId) {
                <span>{{ orgUnitId | findInList: organizationalUnitsOptions }}</span>
              } @else {
                <span>{{ 'Všechny útvary' }}</span>
              }
            </div>
          }
          @if (isNew) {
          <icz-button
            class="inline-block mt-8"
            label="Vybrat kritéria výběru objektů" (onAction)="selectObjects()"
          ></icz-button>
          }
        </div>
        <div class="col grow">
          @if (!isNew && modalData.storageUnitFiller!.statistics!) {
            <icz-section label="Průběh dávky">
              @if (isFinished) {
              <div>
                <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref>
                  {{ 'Založeno ukládacích jednotek:' | translate }}
                </a>
                <span>{{statistics!.storageUnitsInsertedCount}}</span>
              </div>
              <div>
                <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref>
                  {{ 'Úspěšně uloženo objektů:' | translate }}
                </a>
                <span>{{statistics!.entitiesInsertedCount}}</span>
              </div>
              <div>
                <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref>
                  {{ 'Objektů které se nepodařilo uložit:' | translate }}
                </a>
                <span>{{statistics!.entitiesRejectedCount}}</span>
              </div>
              }
              @if (isTested) {
                <div>
                  <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref>
                    {{ 'Očekávaných ukládacích jednotek:' | translate }}
                  </a>
                  <span>{{ statistics!.storageUnitsExpectedCount }}</span>
                </div>
              }
            </icz-section>
          }
          <icz-section label="Popis vytvářených ukládacích jednotek">
            <icz-form-autocomplete
              formControlName="enforceStrictDisposalSchedule"
              label="Ukládací jednotky budou obsahovat pouze shodné skartační režimy"
              [options]="booleanOptions"
            ></icz-form-autocomplete>
            <icz-form-autocomplete
              formControlName="enforceStrictEntityClass"
              label="Ukládací jednotky budou obsahovat pouze shodnou věcnou skupinu"
              [options]="booleanOptions"
            ></icz-form-autocomplete>
            <icz-form-autocomplete
              formControlName="enforceStrictDisposalYear"
              label="Ukládací jednotky budou obsahovat pouze objekty se shodným rokem vyřazení"
              [options]="booleanOptions"
            ></icz-form-autocomplete>
            <icz-form-field
              formControlName="note"
              label="Popis"
            ></icz-form-field>
            <icz-readonly-field
              [value]="auditInfo?.createdBy!"
              label="Založil"
            ></icz-readonly-field>
          </icz-section>
          <icz-section label="Klíče určující cílovou ukládající jednotku">
            <icz-form-autocomplete
              formControlName="classificationAttributes"
              label="Klíče určující cílovou ukládající jednotku"
              [options]="classificationAttributeOptions"
              [isMultiselect]="true"
            ></icz-form-autocomplete>
          </icz-section>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template [iczTab]="StorageUnitFillerTabs.CONTENT_STORAGE_UNITS">
    @if (contentStorageUnitsDataSource) {
      <icz-documents-table
        tableId="storage-unit-filler-content-storage-units"
        [viewType]="StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_STORAGE_UNITS"
        [openQuickPreview]="false"
        [isUnitView]="false"
        [dataSource]="contentStorageUnitsDataSource"
      ></icz-documents-table>
    }
  </ng-template>

  <ng-template [iczTab]="StorageUnitFillerTabs.CONTENT_ENTITIES">
    @if (contentEntitiesDataSource) {
      <icz-table
      id="storage-unit-filler-content-entities"
      #entitiesTable
      [dataSource]="contentEntitiesDataSource"
      [columnsData]="contentEntitiesColumnData"
      [config]="contentEntitiesConfig"
      (selectedRowsChanged)="selectedEntitesChanged($event)"
    >
      <div class="row-group" tools>
        <icz-button (onAction)="withdrawEntitiesFromFiller()" [disabled]="!(selectedStorageFillerContentRows.length > 0 && isPlanned)"
                    [iczTooltip]="isPlanned ? '' : 'Dávka plnění není ve stavu naplánovaná.'"
                    svgIcon="handover_to_distribution" size="large" label="Vyloučit ze zpracování"></icz-button>
      </div>

      <ng-container *iczColumnTemplate="let row from [contentEntitiesDataSource, contentEntitiesColumnData]; id: 'objectClass'">
        <icz-table-entity-icon
          [documentsTableEntity]="$any(row)"
          [view]="StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_ENTITIES"
        ></icz-table-entity-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [contentEntitiesDataSource, contentEntitiesColumnData]; id: 'refNumber'">
        @if (row.refNumber) {
          <span>{{ row.refNumber }}</span>
        } @else {
          <span class="icz-text-muted" [iczTooltip]="WITHOUT_REF_NUMBER | translate">
            {{ 'bez čísla jednacího' | translate }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [contentEntitiesDataSource, contentEntitiesColumnData]; id: 'error'; withEllipsis: true; let column = column">
        @if (row.error) {
          <div class="row">
            <icz-icon svgIcon="error" size="default"></icz-icon>
            <span>
            {{ row.error }}
            </span>
          </div>
        }
      </ng-container>
    </icz-table>
    }
  </ng-template>
</icz-tabs>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this) || isFinished"
              (onAction)="submit()"
              primary label="Uložit"
  ></icz-button>
  @if (isTested) {
    <icz-button leftButtons
                [disabled]="loadingService.isLoading(this)"
                (onAction)="reloadFillerPlanning()"
                label="Znovu naplánovat plničku"
    ></icz-button>
  }
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
