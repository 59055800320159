import {ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  AutoFocusDirective,
  ButtonComponent,
  DISABLED_ELEMENT_TOOLTIP_DELAY,
  InaccessibleDirective,
  LoadingIndicatorService,
  PopoverComponent,
  ResponsivityService,
  TooltipDirective
} from '@icz/angular-essentials';
import {SavedFiltersService} from '../saved-filters.service';
import {
  CheckboxComponent,
  FormFieldComponent,
  IczFormControl,
  IczFormDirective,
  IczFormGroup,
  IczOption,
  OptionsListComponent
} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable, take} from 'rxjs';
import {FilterItemValueTree, isFilterTreeEmpty} from '../../filter-trees.utils';
import {DialogService} from '@icz/angular-modal';
import {AsyncPipe} from '@angular/common';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {ReactiveFormsModule} from '@angular/forms';
import {TableSavedFilters} from '../../table.providers';

/**
 * @internal
 */
const DEFAULT_SAVED_FILTER_SCOPE = '_DEFAULT';

/**
 * @internal
 */
export function openFilterDeleteQuestion(
  savedFilterName: string,
  dialogService: DialogService,
  translateService: TranslateService,
) {
  return dialogService.openQuestionDialog(
    {
      title: 'Smazat uložený filtr',
      question: `${translateService.instant('Opravdu chcete smazat uložený filtr')} "${savedFilterName}"?`,
      description: 'Tuto operaci nelze vrátit.',
    }
  );
}

/**
 * A component implementing filter expression saving and restoration interface.
 */
@Component({
  selector: 'icz-saved-filters',
  templateUrl: './saved-filters.component.html',
  styleUrls: ['./saved-filters.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    TooltipDirective,
    PopoverComponent,
    IczFormDirective,
    FormFieldComponent,
    CheckboxComponent,
    OptionsListComponent,
    CdkOverlayOrigin,
    TranslateModule,
    ReactiveFormsModule,
    InaccessibleDirective,
    AutoFocusDirective,
  ],
})
export class SavedFiltersComponent implements OnInit {

  protected savedFiltersService = inject(SavedFiltersService);
  protected responsivityService = inject(ResponsivityService);
  protected loadingService = inject(LoadingIndicatorService);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  protected isSaveFilterPopupOpen = false;
  protected isSavedFiltersPopupOpen = false;

  /**
   * Fulltext search term.
   */
  @Input()
  searchTerm: Nullable<string> = '';

  /**
   * Structured filter values.
   */
  @Input({required: true})
  activeFilters: Nullable<FilterItemValueTree>;

  /**
   * Scope to save filters to. Scopes are isolated sets of saved filters.
   * One scope usually corresponds to one table business view.
   */
  @Input({required: true})
  savedFilterScope: string = DEFAULT_SAVED_FILTER_SCOPE;

  /**
   * Pregenerated filter name corresponding to activeFilters and searchTerm. Must be
   * an observable because filter name generation might be asynchronous.
   */
  @Input({required: true})
  defaultFilterName$!: Observable<Nullable<string>>;

  /**
   * Emits selected saved filter.
   */
  @Output()
  savedFilterSelected = new EventEmitter<FilterItemValueTree>();

  protected form = new IczFormGroup({
    filterName: new IczFormControl<Nullable<string>>(null),
    setNewFilterAsDefault: new IczFormControl<Nullable<boolean>>(null),
  });

  private currentTableSavedFilters: TableSavedFilters = {};

  protected savedFilterOptions: IczOption[] = [];

  protected get areFiltersEmpty(): boolean {
    return (!this.activeFilters || isFilterTreeEmpty(this.activeFilters)) && !this.searchTerm;
  }

  private get filterName(): string {
    return this.form.get('filterName')!.value!;
  }

  private get setNewFilterAsDefault() {
    return this.form.get('setNewFilterAsDefault')!.value;
  }

  protected get isFilterSaveDialogValid(): boolean {
    return Boolean(this.filterName && !this.isFilterNameDuplicate);
  }

  protected get saveButtonTooltip(): Nullable<string> {
    if (!this.isFilterSaveDialogValid) {
      if (!this.filterName) {
        return 'Není vyplněn název filtru';
      }
      else if (this.isFilterNameDuplicate) {
        return 'Stejný název filtru pro tuto tabulku již existuje. Zvolte prosím jiný.';
      }
    }

    return null;
  }

  private get isFilterNameDuplicate(): boolean {
    return this.currentTableSavedFilters.hasOwnProperty(this.filterName);
  }

  protected readonly DISABLED_ELEMENT_TOOLTIP_DELAY = DISABLED_ELEMENT_TOOLTIP_DELAY;

  /**
   * @internal
   */
  ngOnInit() {
    this.savedFiltersService.savedFilters$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(savedFilters => {
      this.currentTableSavedFilters = savedFilters[this.savedFilterScope] ?? {};
      this.retrieveSavedFilterOptions();
      this.cd.markForCheck();
    });
  }

  protected useDefaultFilterName(): void {
    this.loadingService.doLoading(
      this.defaultFilterName$.pipe(
        take(1),
      ),
      this
    ).subscribe(defaultFilterName => {
      this.form.get('filterName')!.setValue(defaultFilterName);
    });
  }

  protected restoreSavedFilter(selectedFilterOptions: IczOption[]): void {
    const filterKey = selectedFilterOptions[0].value;
    const filterValues = this.currentTableSavedFilters[filterKey as string];

    this.savedFilterSelected.emit(filterValues);

    this.closeSavedFiltersDialog();
  }

  protected saveFilter(): void {
    if (this.isFilterSaveDialogValid) {
      const filterName = this.filterName;

      this.savedFiltersService.saveFilter(
        this.savedFilterScope,
        filterName,
        this.activeFilters,
        this.searchTerm,
      );
      if (this.setNewFilterAsDefault) {
        this.savedFiltersService.updateFilterDefaultState(
          this.savedFilterScope,
          true,
          filterName
        );
      }

      this.closeSaveFilterDialog();
      this.cd.detectChanges();
    }
  }

  protected closeSaveFilterDialog(): void {
    this.isSaveFilterPopupOpen = false;
    this.form.reset();
  }

  protected savedFilterDeleted(savedFilterName: string): void {
    openFilterDeleteQuestion(
      savedFilterName,
      this.dialogService,
      this.translateService,
    ).subscribe(_ => {
      this.savedFiltersService.deleteFilter(this.savedFilterScope, savedFilterName);
      this.isSavedFiltersPopupOpen = false;
      this.retrieveSavedFilterOptions();
      this.cd.detectChanges();
    });
  }

  protected defaultFilterChanged(value: any, option: string) {
    this.savedFiltersService.updateFilterDefaultState(this.savedFilterScope, value, option);
  }

  protected getDefaultValue(option: string) {
    const filterValue = this.currentTableSavedFilters[option as string];
    if (filterValue) {
      return filterValue.isDefault ?? false;
    } else {
      return false;
    }
  }

  protected closeSavedFiltersDialog() {
    this.isSavedFiltersPopupOpen = false;
  }

  private retrieveSavedFilterOptions() {
    this.savedFilterOptions = Object.keys(this.currentTableSavedFilters)
      .map(f => ({value: f, label: f}));
  }

}
